import { StyleProp, ViewStyle } from 'react-native';

import { useCurrentPatientID } from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { GQLUUID } from '@oui/lib/src/types/scalars';

import { Button } from '@src/components/Button';
import { PatientSupporterCard } from '@src/components/PatientSupporterCard';
import {
  usePatientSupportersQuery,
  useReviewPatientSupporterMutation,
} from '@src/components/PatientSupporters.graphql.generated';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { card } from '@src/styles';

export function NewPatientSupporterNotification({ style }: { style?: StyleProp<ViewStyle> }) {
  const { $t } = useI18n();

  const patientID = useCurrentPatientID()!;

  const [reviewPatientSupporter] = useReviewPatientSupporterMutation();

  const { data, loading } = usePatientSupportersQuery({
    variables: {
      patientID,
    },
    skip: !patientID,
  });

  const onDismiss = (patientSupporterID: GQLUUID) => {
    return reviewPatientSupporter({
      variables: {
        patientSupporterID,
      },
    });
  };

  if (loading || !data?.patientByPatientID?.supporters.length) {
    return null;
  }

  return (
    <View spacing={20} style={style} testID="NewPatientSupporterNotification">
      {data?.patientByPatientID?.supporters
        .filter((i) => i.claimed && !i.reviewed)
        .map(({ supporterID, supporter: { person }, relation }) => (
          <View
            testID={`NewPatientSupporterNotification_patientSupporter_${supporterID}`}
            key={supporterID}
            style={[card, { paddingHorizontal: 15, paddingVertical: 20 }]}
            spacing={22}
          >
            <Text
              weight="semibold"
              text={$t({
                id: 'NewPatientSupporterNotification_newSupporter',
                defaultMessage: 'A caregiver has been added',
              })}
              textAlign="center"
            />

            <View
              style={{
                alignItems: 'center',
              }}
            >
              <PatientSupporterCard
                name={`${person.givenName} ${person.familyName}`}
                relation={relation}
              />
            </View>

            <Text
              text={$t({
                id: 'NewPatientSupporterNotification_visiblity',
                defaultMessage:
                  'Heads up, your completed plan to keep your environment safe is visible to them.',
              })}
              textAlign="center"
            />

            <Button
              testID="NewPatientSupporterNotification_dismissButton"
              onPress={() => {
                return onDismiss(supporterID);
              }}
              text={$t({
                id: 'NewPatientSupporterNotification_dismiss',
                defaultMessage: 'Dismiss',
              })}
              variant="text"
              alignSelf="center"
            />
          </View>
        ))}
    </View>
  );
}
