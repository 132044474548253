import { produce } from 'immer';
import { ReactElement } from 'react';

import { RatingType } from '@oui/lib/src/types/graphql.generated';

import { SliderInput } from '../components/SliderInput';

type Value = Array<{ type: RatingType; value: number }>;

// TODO add acessibility support
export function PracticeRatingsInput(props: {
  accessibilityLabel?: string;
  ratingType: RatingType;
  value: Value;
  defaultRating?: number;
  labels?: Record<string, string>;
  onChangeValue: (newValue: Value) => void;
  children?: (renderProps: {
    accessibilityLabel?: string;
    value: number;
    onChangeValue: (newValue: number) => void;
    testID?: string;
  }) => ReactElement;
  testID?: string;
}) {
  const ratingIndex = props.value.findIndex((r) => r.type === props.ratingType);
  const ratingValue =
    ratingIndex === -1
      ? { type: props.ratingType, value: props.defaultRating ?? 0 }
      : props.value[ratingIndex];

  const onChangeValue = (v: number) => {
    const editIndex = ratingIndex === -1 ? props.value.length : ratingIndex;
    const newValue = produce(props.value, (draft) => {
      draft[editIndex] = { type: props.ratingType, value: v };
    });
    props.onChangeValue(newValue);
  };

  return props.children ? (
    props.children({
      accessibilityLabel: props.accessibilityLabel,
      value: ratingValue.value,
      onChangeValue,
      testID: props.testID,
    })
  ) : (
    <SliderInput
      accessibilityLabel={props.accessibilityLabel}
      minValue={1}
      minLabel={props.labels?.['1']}
      maxValue={5}
      maxLabel={props.labels?.['5']}
      labels={props.labels}
      value={ratingValue.value}
      onChangeValue={onChangeValue}
      testID={props.testID}
    />
  );
}
