import { produce } from 'immer';
import { Component } from 'react';

import { ChatInputBaseProps, ChatInputMultipleChoiceProps, Kind } from '@oui/lib/src/types';

import { Button } from '../components/Button';
import { Checkbox } from '../components/Checkbox';
import { View } from '../components/View';

type Props = ChatInputBaseProps & ChatInputMultipleChoiceProps & { legacyChoice?: true };
type State = { selectedOptions: { [key: string]: boolean } };

export class ChatInputMultipleChoice extends Component<Props, State> {
  static defaultProps = {
    label: {},
  };

  state: State = { selectedOptions: {} };

  getSelectedValues() {
    return Object.entries(this.state.selectedOptions)
      .map(([i, selected]) => {
        return selected ? i : '';
      })
      .filter((v) => !!v)
      .map((v) => Number(v));
  }

  handleSubmit = () => {
    const { onInput } = this.props;
    if (onInput) {
      if (this.props.legacyChoice) {
        onInput({ kind: Kind.InputChoice, props: this.getSelectedValues() });
      } else {
        onInput({ kind: Kind.InputMultipleChoice, props: this.getSelectedValues() });
      }
    }
  };

  render() {
    const { style } = this.props;
    return (
      <View
        style={[
          {
            paddingTop: 8,
            paddingLeft: 16,
            paddingBottom: 8,
            paddingRight: 16,
          },
          style,
        ]}
        spacing={10}
      >
        <View
          spacing={8}
          accessibilityRole="combobox"
          accessibilityLabel={this.props.lastTextContent}
        >
          {Object.entries(this.props.label).map(([i, str]) => {
            const toggle = () => {
              this.setState(
                produce((state) => {
                  state.selectedOptions[i] = !state.selectedOptions[i];
                }),
              );
            };

            return (
              <Checkbox
                horizontal
                key={i.toString()}
                onChangeValue={toggle}
                label={str}
                value={this.state.selectedOptions[i]}
                testID={`ChatInputMultipleChoice_${i}`}
              />
            );
          })}
        </View>
        <Button
          testID="ChatInputMultipleChoice_submit"
          text="Choose"
          alignSelf="center"
          onPress={this.handleSubmit}
          disabled={
            !(
              this.getSelectedValues().length >= this.props.min &&
              this.getSelectedValues().length <= this.props.max
            )
          }
        />
      </View>
    );
  }
}

export default ChatInputMultipleChoice;
