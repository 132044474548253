import { useCallback, useEffect, useRef, useState } from 'react';
import { Platform, StyleSheet, TextInput, View } from 'react-native';

import { ChatInputBaseProps, ChatInputTextProps, Kind } from '@oui/lib/src/types';

import { Icon } from './Icon';
import { Text } from './Text';
import { getStyles } from './TextInput';
import { useTheme } from '../styles';

export function ChatInputText({
  onInput = () => {},
  disabled = false,
  ...props
}: ChatInputTextProps & ChatInputBaseProps) {
  const { theme, Color } = useTheme();
  const inputRef = useRef<TextInput>(null);
  const focusTimeoutRef = useRef(0 as unknown as NodeJS.Timeout);
  const [state, setState] = useState<{ isFocused: boolean; value: null | string; valid: boolean }>({
    isFocused: false,
    value: null,
    valid: false,
  });

  useEffect(() => {
    // Not sure why the autoFocus prop on TextInput isn't working as expected,
    // but we seem to need a short delay to get the focus to actually take effect
    if (props.autoFocus) {
      focusTimeoutRef.current = global.setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100);
    }

    return () => clearTimeout(focusTimeoutRef.current);
  }, [props.autoFocus]);

  const emit = useCallback(
    (value: string) => {
      if (onInput) {
        onInput({ kind: Kind.InputText, props: value.trim() });
      }
    },
    [onInput],
  );

  const handleInput = useCallback(
    ({ value: next }: { value: string }) => {
      const { value: curr } = state;
      if (curr !== next) {
        setState((curr) => ({ ...curr, value: next, valid: next !== '' }));
      }
    },
    [state],
  );

  const handleSubmit = useCallback(() => {
    console.log('submit');
    const { value: curr, valid } = state;
    if (curr !== null && valid) {
      emit(curr!);
      setState((curr) => ({ ...curr, value: null, valid: false }));
      if (inputRef.current) inputRef.current.blur();
    }
  }, [state, emit]);

  const { value, valid } = state;
  const change = (text: string) => handleInput({ value: text });
  const submit = () => handleSubmit();

  // Due to existing RN issue, we need to customize our TextInput behavior on Android
  // otherwise when our TextInput has a value, the lastTextContent won't be read by TalkBack.
  // Usually, we can get around this using the placeholder attribute, but in this case our
  // the placeholder is the last chat message which is likely too long to fit without wrapping
  // and expanding our text box.
  // https://github.com/facebook/react-native/issues/26739
  const inner = (
    <>
      <TextInput
        importantForAccessibility="no-hide-descendants"
        accessibilityLabel={props.lastTextContent}
        testID="ChatInputText_input"
        ref={inputRef}
        keyboardType={props.keyboardType}
        style={[
          getStyles(theme, Color).outlined,
          { flex: 1, paddingRight: 40, height: '100%' },
          disabled ? { opacity: 0.5 } : null,
        ]}
        value={value || ''}
        onChangeText={change}
        onSubmitEditing={valid ? submit : undefined}
        onFocus={() => setState((curr) => ({ ...curr, isFocused: true }))}
        onBlur={() => setState((curr) => ({ ...curr, isFocused: false }))}
        returnKeyType="send"
        editable={!disabled}
        multiline
        // @ts-expect-error web only
        rows={Platform.OS === 'web' ? '1' : undefined}
      />
      {disabled || value?.length ? null : (
        <View
          style={[StyleSheet.absoluteFillObject, { justifyContent: 'center', paddingLeft: 16 }]}
          pointerEvents="none"
        >
          <Text
            text={props.placeholder || 'Enter text'}
            size={18}
            lineHeight={20}
            color="#c7c7c7"
            accessibilityRole="none"
          />
        </View>
      )}
    </>
  );

  return (
    <View
      testID="ChatInputText"
      style={{
        flexGrow: 1,
        maxHeight: 120,
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 10,
      }}
    >
      {Platform.OS === 'android' ? (
        <View
          accessible
          accessibilityLabel={[
            state.isFocused ? 'Editing' : undefined,
            value,
            'Edit box',
            props.lastTextContent,
          ]
            .filter((v) => !!v)
            .join('.')}
          style={{ flexDirection: 'row', flex: 1 }}
          accessibilityActions={[{ name: 'activate', label: state.isFocused ? 'blur' : 'focus' }]}
          onAccessibilityAction={(event) => {
            if (event.nativeEvent.actionName === 'activate') {
              if (state.isFocused) {
                inputRef.current?.blur();
              } else {
                inputRef.current?.focus();
              }
            }
          }}
        >
          {inner}
        </View>
      ) : (
        inner
      )}
      {disabled ? null : (
        <Icon
          accessibilityLabel="send"
          color={valid ? theme.color.primary100 : theme.color.gray400}
          disabled={!valid || disabled}
          name={valid ? 'post-ready' : 'post-open'}
          onPress={submit}
          size={40}
          style={{ right: 2, position: 'absolute' }}
          testID="ChatInputText_submit"
        />
      )}
    </View>
  );
}

export default ChatInputText;
