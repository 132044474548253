import { ComponentProps, ReactNode, useState } from 'react';
import { Pressable } from 'react-native';

import { Icon } from './Icon';
import { Text } from './Text';
import { View } from './View';
import { AccessibleInput } from '../hooks/useAccessibleInput';
import { useTheme } from '../styles';

export function SegmentedControl<T extends string>({
  accessibilityLabel,
  value,
  onChangeValue,
  items,
  testID,
}: {
  accessibilityLabel?: string;
  onChangeValue: (value: Array<T>) => void;
  value: Array<T>;
  items: { label: string; icon?: ComponentProps<typeof Icon>['name']; value: T }[];
  testID?: string;
}) {
  const { theme } = useTheme();
  const [widths, setWidths] = useState({ container: 0, items: [] as number[] });

  const totalWidth = widths.items.reduce((carry, item) => {
    if (carry === -1 || typeof item !== 'number') return -1;
    return carry + item;
  }, 0);
  const rows =
    widths.container > 0 && totalWidth > 0 ? Math.ceil(totalWidth / widths.container) : 1;
  const itemsPerRow = Math.floor(items.length / rows);

  return (
    <View
      testID={testID}
      onLayout={(e) => {
        if (e.nativeEvent.layout.width !== widths.container) {
          setWidths({ ...widths, container: e.nativeEvent.layout.width });
        }
      }}
      row
      style={{
        borderWidth: 1,
        borderColor: theme.color.gray600,
        borderRadius: 20,
        alignItems: 'stretch',
        flexWrap: 'wrap',
        overflow: 'hidden',
      }}
      accessibilityRole="combobox"
      accessibilityLabel={accessibilityLabel}
    >
      {widths.container
        ? items.map((item, index) => {
            const isSelected = value.includes(item.value);

            const currentRow = Math.floor(index / itemsPerRow);
            // const isFirstRow = currentRow === 0;
            const isLastRow = currentRow === rows - 1;
            // const isFirst = index === 0;
            const isLast = index % itemsPerRow === itemsPerRow - 1;

            return (
              <Pressable
                accessibilityRole="checkbox"
                accessibilityState={{
                  selected: isSelected,
                }}
                testID={testID ? `${testID}_${item.value}` : undefined}
                onLayout={(e) => {
                  if (e.nativeEvent.layout.width !== widths.items[index]) {
                    const newItems = [...widths.items];
                    newItems[index] = Math.min(e.nativeEvent.layout.width, widths.container / 2);
                    setWidths({ ...widths, items: newItems });
                  }
                }}
                key={item.value}
                onPress={() => {
                  if (isSelected) {
                    onChangeValue(value.filter((v) => item.value !== v));
                  } else {
                    onChangeValue([...value, item.value]);
                  }
                }}
                style={[
                  {
                    flexGrow:
                      widths.container && widths.items.length === items.length ? 1 : undefined,
                    alignItems: 'center',
                    paddingHorizontal: 6,
                    paddingVertical: 10,
                    borderColor: 'transparent',
                    borderEndWidth: 1,
                  },
                  rows > 1 ? { flexGrow: 1, flexBasis: `${100 / rows}%` } : null,
                  isSelected ? { backgroundColor: theme.color.primary100 } : null,
                  isLastRow
                    ? null
                    : { borderBottomColor: theme.color.gray600, borderBottomWidth: 1 },
                  isLast
                    ? null
                    : {
                        borderEndWidth: 1,
                        borderColor: theme.color.gray600,
                      },
                ]}
              >
                {item.icon ? (
                  <Icon
                    name={item.icon}
                    color={isSelected ? 'white' : theme.color.gray500}
                    style={{ marginBottom: 4 }}
                  />
                ) : null}
                <Text
                  size={13}
                  text={item.label}
                  color={isSelected ? 'white' : theme.color.gray500}
                  weight="semibold"
                />
              </Pressable>
            );
          })
        : null}
    </View>
  );
}

export function SegmentedControlInput(
  props: ComponentProps<typeof SegmentedControl> &
    Omit<ComponentProps<typeof AccessibleInput>, 'children' | 'label' | 'accessibilityLabel'> &
    (
      | {
          label?: string;
          accessibilityLabel?: string;
        }
      | {
          label?: () => ReactNode;
          accessibilityLabel: string;
        }
    ),
) {
  return (
    <AccessibleInput {...props}>
      {({ ref, ...accessibilityProps }) => <SegmentedControl {...props} {...accessibilityProps} />}
    </AccessibleInput>
  );
}
