import * as Sentry from '@sentry/core';
import {
  cloneElement,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Platform, TouchableWithoutFeedback } from 'react-native';
import { AutoScrollFlatList } from 'react-native-autoscroll-flatlist';

import { Actor, ChatEnvelope, ChatSide, EnvelopeHistory, Kind } from '@oui/lib/src/types';

import { useAccessibilityContext } from './AccessibilityContext';
import { useAppContext } from './AppContext';
import ChatArtifactPreview from './ChatArtifactPreview';
import ChatAsset, { getAccessibilityTextForChatAsset } from './ChatAsset';
import { ChatHeader } from './ChatHeader';
import ChatTable from './ChatTable';
import { AnimatingChatText, ChatText } from './ChatText';
import { ChatTypingIndicator } from './ChatTypingIndicator';
import { useConversationContext } from './ConversationContext';
import { Icon } from './Icon';
import { Text } from './Text';
import { View } from './View';
import { Shadow, useTheme } from '../styles';

const contentContainerStyle = { flexGrow: 1, justifyContent: 'flex-end' as const };

const keyExtractor = ({ envelope: { ID } }: { envelope: ChatEnvelope }) => `chat-${ID}`;

function getAccessibilityTextForChatEnvelope(envelope: ChatEnvelope): string {
  switch (envelope.kind) {
    case Kind.ChatText:
      // TODO handle markdown here...
      return envelope.props.text.join('').replace(/\*/g, '');
    case Kind.ChatArtifactPreview:
      return `Artifact preview: ${envelope.props.linkText}`;
    case Kind.ChatAsset:
      return getAccessibilityTextForChatAsset(envelope.props);
    case Kind.ChatTable:
      return `Table with columns: ${envelope.props.data[0].join(', ')}`;
  }
}

const ChatItem = memo(
  ({
    item: curr,
    sameSideAsPrevious,
    isFirstEnvelope,
  }: {
    item: ChatEnvelope;
    sameSideAsPrevious: boolean;
    isFirstEnvelope: boolean;
  }) => {
    const { flags } = useAppContext();
    const { theme } = useTheme();

    let el = null;
    switch (curr.kind) {
      case Kind.ChatText:
        const { text, ...rest } = curr.props;
        el = (
          <ChatText
            {...rest}
            value={text[0]}
            side={curr.from === Actor.User ? ChatSide.END : ChatSide.START}
            sameSideAsPrevious={sameSideAsPrevious}
          />
        );
        break;
      case Kind.ChatArtifactPreview:
        el = (
          <ChatText
            accessible={false}
            bubbleStyle={{ borderColor: theme.color.primary100 }}
            value={<ChatArtifactPreview {...curr.props} />}
            side={curr.from === Actor.User ? ChatSide.END : ChatSide.START}
            sameSideAsPrevious={sameSideAsPrevious}
          />
        );
        break;
      case Kind.ChatTable:
        el = (
          <ChatText
            accessible={false}
            bubbleStyle={{ maxWidth: '100%', padding: 0 }}
            value={<ChatTable {...curr.props} />}
            side={curr.from === Actor.User ? ChatSide.END : ChatSide.START}
            sameSideAsPrevious={sameSideAsPrevious}
          />
        );
        break;
      case Kind.ChatAsset:
        el = (
          <ChatText
            accessible={false}
            bubbleStyle={{ maxWidth: '100%', padding: 0 }}
            value={<ChatAsset {...curr.props} />}
            side={curr.from === Actor.User ? ChatSide.END : ChatSide.START}
            sameSideAsPrevious={sameSideAsPrevious}
          />
        );
        break;
    }

    const inner = cloneElement(el, { extraMargin: !(sameSideAsPrevious || isFirstEnvelope) });
    return flags.showEnvelopeIDInChat ? (
      <View>
        <Text text={curr.ID} style={{ marginBottom: -8, marginLeft: 10 }} />
        {inner}
      </View>
    ) : (
      inner
    );
  },
);

function ListHeader() {
  const { botName, botAvatar } = useConversationContext();
  return (
    <View>
      <ChatHeader />
      <View flex={1} />
      <View row style={{ paddingHorizontal: 10 }} spacing={8}>
        {botAvatar}
        <Text text={botName} weight="semibold" size={17} />
      </View>
    </View>
  );
}

type FlatListData = {
  envelope: ChatEnvelope;
  sameSideAsPrevious: boolean;
  isFirstEnvelope: boolean;
};
function getFlatListData(envelopes: ChatEnvelope[]): FlatListData[] {
  return [...envelopes].reverse().map((envelope, i, arr) => ({
    envelope,
    sameSideAsPrevious: envelope.from === arr[i + 1]?.from,
    isFirstEnvelope: i === arr.length - 1,
  }));
}

export const ChatV2 = forwardRef(
  (props: { messages: (ChatEnvelope & EnvelopeHistory)[]; hasEnvelopes: boolean }, ref) => {
    const [history, setHistory] = useState(() => getFlatListData(props.messages));
    const [newEnvelope, setNewEnvelope] = useState<ChatEnvelope | null>(null);
    const alreadyWarned = useRef(false);
    const timeoutRef = useRef<NodeJS.Timeout>(0 as unknown as NodeJS.Timeout);
    const callbackRef = useRef<Function>();
    const listRef = useRef<AutoScrollFlatList<FlatListData>>(null);
    const { announceForAccessibility } = useAccessibilityContext();
    const [listKeyHack, setListKeyHack] = useState('list');
    const layoutBugTimeoutHackRef = useRef<NodeJS.Timeout>();
    const { accentColor } = useConversationContext();

    useImperativeHandle(ref, () => ({
      scrollToEnd: () => listRef.current?.scrollToEnd(),
    }));

    useEffect(() => {
      const lastMessage = props.messages[props.messages.length - 1];
      const lastHistory = history[0];
      // history may be send multiple times if we first load cached history then new history from
      // the server, so check if new messages are history and not yet added to local history state
      if (lastMessage?.history && (!lastHistory || lastHistory.envelope.ID !== lastMessage.ID)) {
        setHistory(getFlatListData(props.messages));
        return;
      }

      if (history.length === props.messages.length) return;

      const lengthDiff = props.messages.length - history.length;
      if (Math.abs(lengthDiff) > 1 && alreadyWarned.current === false) {
        alreadyWarned.current = true;
        Sentry.withScope((scope) => {
          scope.setExtras({
            lengthDiff,
            lastHistoryID: history[0]?.envelope.ID,
            lastMessageID: props.messages[props.messages.length - 1]?.ID,
            secondToLastMessageID: props.messages[props.messages.length - 2]?.ID,
            thirdToLastMessageID: props.messages[props.messages.length - 3]?.ID,
          });
          Sentry.captureMessage('Chat has multiple new messages but only 1 is expected');
        });
      }

      const newMsg = props.messages[props.messages.length - lengthDiff];
      if (!newMsg) return;

      const sameSideAsPrevious = history[0]?.envelope.from === newMsg.from;

      function callback() {
        setHistory((h) => [
          { envelope: newMsg, sameSideAsPrevious, isFirstEnvelope: h.length === 0 },
          ...h,
        ]);
        const chatAccessibilityText = getAccessibilityTextForChatEnvelope(newMsg);
        announceForAccessibility(
          sameSideAsPrevious
            ? chatAccessibilityText
            : newMsg.from === Actor.Bot
              ? `Aviva sent: ${chatAccessibilityText}`
              : `You sent: ${chatAccessibilityText}`,
        );
        setNewEnvelope(null);
      }

      if (Platform.OS === 'web' && global.e2e) {
        callback();
        return;
      }

      setNewEnvelope(newMsg);

      clearTimeout(timeoutRef.current);
      if (callbackRef.current) {
        callbackRef.current();
      }
      timeoutRef.current = setTimeout(callback, newMsg.from === Actor.User ? 1300 : 1300);

      return () => {
        clearTimeout(timeoutRef.current);
        callbackRef.current = undefined;
      };
    }, [history, props.messages, announceForAccessibility]);

    const sameSideAsPrevForNewEnvelope = history[0]?.envelope.from === newEnvelope?.from;

    const renderItem = useCallback(({ item, index: i }: { item: FlatListData; index: number }) => {
      return (
        <ChatItem
          item={item.envelope}
          sameSideAsPrevious={item.sameSideAsPrevious}
          isFirstEnvelope={item.isFirstEnvelope}
        />
      );
    }, []);

    return (
      <AutoScrollFlatList<FlatListData>
        ref={listRef}
        key={listKeyHack}
        threshold={200}
        showNewItemAlert={false}
        windowSize={101}
        initialNumToRender={100}
        maxToRenderPerBatch={100}
        contentContainerStyle={contentContainerStyle}
        data={[...history].reverse()}
        keyExtractor={keyExtractor}
        ListEmptyComponent={() =>
          props.hasEnvelopes ? null : (
            <View>
              <ChatText value={<ChatTypingIndicator />} side={ChatSide.START} />
            </View>
          )
        }
        ListHeaderComponent={ListHeader}
        ListFooterComponent={() => {
          return newEnvelope ? (
            <AnimatingChatText
              sameSideAsPrevious={sameSideAsPrevForNewEnvelope}
              actor={newEnvelope.from}
            >
              <ChatItem
                item={newEnvelope}
                sameSideAsPrevious={sameSideAsPrevForNewEnvelope}
                isFirstEnvelope={history.length === 0}
              />
            </AnimatingChatText>
          ) : null;
        }}
        renderItem={renderItem}
        onScroll={() => clearTimeout(layoutBugTimeoutHackRef.current!)}
        indicatorComponent={
          <TouchableWithoutFeedback
            accessibilityRole="button"
            accessibilityLabel="Scroll to end"
            onPress={() => {
              layoutBugTimeoutHackRef.current = setTimeout(() => {
                setListKeyHack(Math.random.toString());
              }, 200);
              listRef.current?.scrollToEnd();
            }}
          >
            <View
              style={[
                {
                  width: 36,
                  height: 36,
                  borderRadius: 20,
                  backgroundColor: 'white',
                  position: 'absolute',
                  bottom: 12,
                  right: 12,
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                Shadow.low,
              ]}
            >
              <Icon name="double-chevron-down" color={accentColor} size={18} />
            </View>
          </TouchableWithoutFeedback>
        }
      />
    );
  },
);
