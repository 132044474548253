import { useFocusEffect, useRoute } from '@react-navigation/native';
import { useCallback, useState } from 'react';

import { ChatInputArtifact } from '@oui/app-core/src/components/ChatInputArtifact';
import { RoundedSection } from '@oui/app-core/src/components/RoundedSection';
import { InputArtifactResult } from '@oui/lib/src/types/actionEnvelopes';

import { Text } from '@src/components/Text';
import { Kind, StackScreenProps } from '@src/types';

export function TestArtifactResult() {
  const route = useRoute<StackScreenProps<'TestArtifactResult'>['route']>();
  const [attempt, setAttempt] = useState(0);
  const [result, setResult] = useState<InputArtifactResult>();

  useFocusEffect(
    useCallback(() => {
      return () => setResult(undefined);
    }, []),
  );

  return (
    <RoundedSection color="#ebf1ed" secondaryColor="white" title="Test" testID="TestArtifactResult">
      {result ? (
        <Text
          text={`Complete? ${result.complete}`}
          testID={
            result.complete
              ? 'TestArtifactResult_resultComplete'
              : 'TestArtifactResult_resultNotComplete'
          }
        />
      ) : null}
      {result?.ID ? <Text text={result.ID} testID={'TestArtifactResult_resultID'} /> : null}
      <ChatInputArtifact
        key={attempt.toString()}
        onInput={(r) => {
          if (r.kind === Kind.InputArtifact) {
            setResult(r.props);
            setAttempt(attempt + 1);
          }
        }}
        artifactName={route.params.artifactName}
        params={route.params.params ? JSON.parse(route.params.params) : undefined}
        buttonText="Start artifact"
      />
    </RoundedSection>
  );
}
