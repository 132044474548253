import { SegmentedProgressBar } from '../components/SegmentedProgressBar';
import { Text } from '../components/Text';
import { View } from '../components/View';
import { useI18n } from '../lib/i18n';
import { useTheme } from '../styles';

export function LearnProgress({
  numCompleted,
  numTotal,
  backgroundColor,
}: {
  numCompleted: number;
  numTotal: number;
  backgroundColor?: string;
}) {
  const { theme } = useTheme();
  const { $t } = useI18n();
  return (
    <View
      testID="LearnProgress"
      style={{
        backgroundColor: backgroundColor ?? theme.color.accentTwo100,
        padding: 10,
        marginHorizontal: -20,
      }}
      row
      accessibilityLabel={$t(
        {
          id: 'LearnProgress_accessibilityLabel',
          defaultMessage: 'Learn progress. {numCompleted} of {numTotal} completed',
        },
        { numCompleted, numTotal },
      )}
    >
      <Text
        text={$t(
          { id: 'LearnProgress_progressText', defaultMessage: '{numCompleted}/{numTotal}' },
          { numTotal, numCompleted },
        )}
        testID="LearnProgress_progressText"
        color="white"
        style={{ marginHorizontal: 12 }}
      />
      <SegmentedProgressBar
        length={numTotal}
        progress={numCompleted}
        variant="dark"
        height={10}
        gap={1}
      />
    </View>
  );
}
