import { createContext, PropsWithChildren, ReactNode, useContext } from 'react';

import AvivaAvatar from '../assets/chat_avatar.svg';
import { useTheme } from '../styles';

type ConversationTheme = {
  botName: string;
  botAvatar: ReactNode;
  receivedMessageBorderColor: string;
  sentMessageBackgroundColor: string;
  showTabsAfterCompletion: boolean;
  showTabsBeforeCompletion: boolean;
  accentColor: string;
};

const ConversationContext = createContext<ConversationTheme>({
  botName: 'Aviva',
  botAvatar: <AvivaAvatar width={40} height={40} accessibilityLabel={undefined} />,
  receivedMessageBorderColor: 'black',
  sentMessageBackgroundColor: '#eee',
  showTabsAfterCompletion: false,
  showTabsBeforeCompletion: false,
  accentColor: 'black',
});

export function ConversationProvider({
  children,
  ...props
}: PropsWithChildren<Partial<ConversationTheme>>) {
  const { theme } = useTheme();
  return (
    <ConversationContext.Provider
      value={{
        botName: 'Aviva',
        botAvatar: <AvivaAvatar width={40} height={40} accessibilityLabel={undefined} />,
        receivedMessageBorderColor: theme.color.accent100,
        sentMessageBackgroundColor: theme.color.accentTwo100,
        showTabsAfterCompletion: false,
        showTabsBeforeCompletion: false,
        accentColor: theme.color.accent100,
        ...props,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
}

export function useConversationContext() {
  return useContext(ConversationContext);
}
