import { useApolloClient } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/core';
import { StackActions } from '@react-navigation/native';
import * as Sentry from '@sentry/core';
import equals from 'fast-deep-equal';
import { produce } from 'immer';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';

import { retry } from '@oui/lib/src/retry';
import {
  ActionEnvelope,
  Actor,
  ChatEnvelope,
  ChatTextProps,
  Envelopes,
  InputEnvelope,
  Kind,
} from '@oui/lib/src/types';

import { useAccessibilityContext } from '../components/AccessibilityContext';
import { IS_PRODUCTION } from '../constants';
import { SideEffect } from '../hooks/useChatSideEffect';
import {
  getEnvelopeDuration,
  isChatEnvelope,
  isInputEnvelope,
  isSideEffectEnvelope,
} from '../lib/envelopeType';
import { logEvent } from '../lib/log';
import { getMmkv } from '../lib/mmkv';
import { fetchIsConnected } from '../lib/NetInfo';
import { transitionBot, type transitionBot as transitionBotType } from '../lib/transitionBot';
import { CoreRootStackParamList as RootStackParamList } from '../types/navigation';

type WritePayload = ActionEnvelope & { ID: string; t: string | number; from: Actor };
type MessageCallback = (msg: Envelopes) => void;
type ChatTextEnvelope = {
  t?: number | string;
  ID: string;
  from: Actor;
  history?: boolean;
  kind: Kind.ChatText;
  props: ChatTextProps;
};

export enum ClientStatus {
  UNKNOWN = 'unknown',
  DISCONNECTING = 'disconnecting',
  DISCONNECTED = 'disconnected',
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
}

const TEST_CONVO_PREFIX = 'TEST::';
const TEST_CONVO_SPEED_FACTOR = 1;
const BUBBLE_APPEAR_OFFSET = 750;

type ClientReturn = {
  checkNetworkStatus: () => Promise<boolean>;
  status: ClientStatus;
  subscribe: (convo: string, cb: MessageCallback) => void;
  unsubscribe: (convo: string, cb: MessageCallback) => void;
  send: (convo: string, payload: WritePayload) => void;
};

function getFlatChatTextEnvelopesFromChatTextEnvelope(
  message: ChatTextEnvelope,
): ChatTextEnvelope[] {
  const ID = message.ID;
  const props = message.props || [];
  const len = props.text.length;
  const arr = new Array(len);
  for (let i = 0; i < len; i += 1) {
    // We manually create a new object vs using immer here because
    // it's not playing nicely with history envlopes loaded from mmkv which
    // are frozen.
    arr[i] = {
      ...message,
      props: {
        ...message.props,
        text: [message.props.text[i]],
      },
      ID: `${ID}-${message.from}-${i}-${message.t}`,
    };
  }

  return arr;
}

function useTestMQTT(): ClientReturn {
  const timeoutRef = useRef<NodeJS.Timeout>(0 as any);

  function sendNextEnvelope(convo: string, playIndex: number, callback: MessageCallback) {
    const data = require('../_testdata/test.json');
    const currEnvelope = data[playIndex];
    const nextEnvelope = data[playIndex + 1];

    callback(currEnvelope);

    if (nextEnvelope) {
      const delay =
        nextEnvelope.t && currEnvelope.t
          ? new Date(nextEnvelope.t).getTime() - new Date(currEnvelope.t).getTime()
          : 100;
      timeoutRef.current = global.setTimeout(() => {
        sendNextEnvelope(convo, playIndex + 1, callback);
      }, delay / TEST_CONVO_SPEED_FACTOR);
    }
  }

  return {
    send: (convo, e) => {
      console.log('sending', e, 'on', convo);
    },
    status: ClientStatus.CONNECTED,
    checkNetworkStatus: () => Promise.resolve(true),
    subscribe: (convo, callback) => {
      sendNextEnvelope(convo, 0, callback);
    },
    unsubscribe: () => {
      clearTimeout(timeoutRef.current);
    },
  };
}

let transitionBotFn = transitionBot;

export function setTransitionBot(_transitionBot: typeof transitionBotType) {
  transitionBotFn = _transitionBot;
}

const transitionBotWithRetries: typeof transitionBotType = (client, ...args) => {
  if (args[0] === 'e2e-test' && (args[2] as any)?.ID === 'mux-body' && args[2]?.props === 2) {
    return new Promise((_, rej) => {
      rej('simulated bot error');
    });
  }

  return retry(() => transitionBotFn(client, ...args), {
    timeout: 11000,
    logger: (message) => {
      Sentry.addBreadcrumb({
        message: 'transitionBot retry result',
        // we dont include client in the ...args pattern above so we dont accidentally
        // try to stringify it and cause a cyclic JSON error
        data: { message, config: args[0], stateHash: args[1], actionKind: args[2]?.kind },
      });
    },
  });
};

async function cacheBotHistory(
  convo: string,
  history: Envelopes[],
  isInitialRemoteHistory = false,
) {
  if (Platform.OS === 'web') {
    return {
      newHistory: history,
      cachedHistory: history,
      fullHistory: history,
      requiresReset: false,
    };
  }
  let cachedHistory = await getBotHistory(convo);

  let fullHistory;
  let newHistory;
  const requiresReset = history.length < cachedHistory.length && isInitialRemoteHistory;
  if (requiresReset) {
    fullHistory = history;
    newHistory = history;
    cachedHistory = [];
  } else {
    const latestTimestamp = cachedHistory[cachedHistory.length - 1]?.t ?? 0;
    newHistory = history
      .filter((h) => h.t! > latestTimestamp)
      .map((h) => ({ ...h, history: true }));
    fullHistory = [...cachedHistory, ...newHistory];
  }
  getMmkv('bot').set(convo, JSON.stringify(fullHistory));

  return {
    cachedHistory,
    fullHistory,
    newHistory,
    requiresReset,
  };
}

async function getBotHistory(convo: string): Promise<Envelopes[]> {
  if (Platform.OS === 'web') return [];
  const mmkv = getMmkv('bot');
  const storedValue = mmkv.getString(convo);
  return storedValue ? JSON.parse(storedValue) : [];
}

function useStateMachine(
  onHistory: (envelopes: Envelopes[], requiresReset?: boolean) => void,
): ClientReturn {
  const { dispatch } = useNavigation();
  const { params } = useRoute();
  const callbacksRef = useRef<{ [key: string]: MessageCallback[] }>({});
  const stateRef = useRef<string | null>(null);
  const [isConnected, setIsConnected] = useState(true);
  const apollo = useApolloClient();

  const checkNetworkStatus = useCallback(async () => {
    const result = await fetchIsConnected(1000);
    Sentry.addBreadcrumb({
      category: 'net-info',
      message: `isConnected?: ${result}`,
    });
    setIsConnected(result);
    return result;
  }, []);

  const performTransition = useCallback(
    (convo: string, payload: WritePayload | null) => {
      transitionBotWithRetries(apollo, convo, stateRef.current, payload)
        .catch(async (e) => {
          if (e.toString() !== 'simulated bot error') {
            Sentry.captureException('transitionBotWithRetries error', { extra: { data: e } });
            Sentry.captureException(e);
          }
          return { error: true, envelopes: [] as Envelopes[], state: null };
        })
        .then(async ({ error, envelopes, state }) => {
          Sentry.addBreadcrumb({
            category: 'bot',
            message: 'transition-response',
            data: { error: error.toString(), envelopeIDs: envelopes.map((e) => e.ID), state },
          });

          const { newHistory, requiresReset } = await cacheBotHistory(
            convo,
            envelopes,
            payload === null,
          );

          if (envelopes?.[0]?.history) {
            onHistory(newHistory, requiresReset);
            return;
          }

          if (error) {
            const connected = await checkNetworkStatus();
            Sentry.withScope((scope) => {
              scope.setExtras({
                state: stateRef.current,
                payload,
              });
              Sentry.captureException('Bot ERROR');
            });
            logEvent('bot_error', { state: stateRef.current });
            envelopes = [
              {
                ID: 'error-' + Date.now(),
                kind: Kind.ChatText,
                from: Actor.Bot,
                props: {
                  text: connected
                    ? [
                        'Uh oh. Something went wrong.',
                        'Please try again and if your problem persists, come back later.',
                      ]
                    : [
                        'Please make sure you are connected to the internet and try again.',
                        'If your problem persists, come back later.',
                      ],
                },
              },
              {
                ID: 'error-choice',
                from: Actor.Bot,
                kind: Kind.InputChoice,
                props: {
                  max: 1,
                  min: 1,
                  variant: 'button',
                  label: {
                    '0': 'Come back later',
                    '1': 'Try again',
                  },
                },
              },
            ];
          }
          // console.log('callback', envelopes, callbacksRef, convo);
          stateRef.current = state;
          callbacksRef.current[convo]?.forEach((cb) => {
            envelopes.forEach(cb);
          });
        });
    },
    [checkNetworkStatus, onHistory, apollo],
  );

  return {
    checkNetworkStatus,
    status: isConnected ? ClientStatus.CONNECTED : ClientStatus.DISCONNECTED,
    subscribe: useCallback(
      async (convo, callback) => {
        // console.log('subscribe', convo);
        if (!callbacksRef.current[convo]) callbacksRef.current[convo] = [];
        callbacksRef.current[convo].push(callback);
        const history = await getBotHistory(convo);
        if (history.length) {
          onHistory(history);
        }
        performTransition(convo, null);
      },
      [performTransition, onHistory],
    ),
    unsubscribe: useCallback((convo, callback) => {
      // console.log('unsubscribe', convo);
      if (!callbacksRef.current[convo]) return;
      callbacksRef.current[convo] = callbacksRef.current[convo]?.filter((sub) => sub !== callback);
    }, []),
    send: useCallback(
      (convo, payload) => {
        // console.log(convo, payload, stateRef.current);
        const isWelcome = convo === 'welcome';

        if (payload.kind) {
          if (payload.ID === 'error-choice' && payload.kind === Kind.InputChoice) {
            if (payload.props[0] === 0) {
              callbacksRef.current[convo]?.forEach((cb) => {
                const envelopes: Envelopes[] = [
                  {
                    ID: 'nav-home',
                    from: Actor.Bot,
                    kind: Kind.SideEffectNavigate,
                    props: {
                      routeName: isWelcome ? 'LoggedInWelcome' : 'home',
                    },
                  },
                ];
                envelopes.forEach(cb);
              });
            } else {
              dispatch(
                StackActions.replace(isWelcome ? 'WelcomeConversation' : 'Conversation', params),
              );
            }
            return;
          }

          performTransition(convo, payload);
        }
      },
      [], // eslint-disable-line
    ),
  };
}

export function useConversation(
  convo: string,
  completed: boolean,
  handleSideEffect: (effect: SideEffect) => void,
) {
  const lastSentRef = useRef<WritePayload>();
  const queueRef = useRef<Envelopes[]>([]);
  type MessagesObject = {
    chat: ChatEnvelope[];
    input: InputEnvelope[];
  };
  const [messages, setMessages] = useState<MessagesObject>({
    chat: [],
    input: [],
  });
  const { isScreenReaderEnabled, isAnnouncingIOS } = useAccessibilityContext();
  const isAnnouncingIOSRef = useRef(isAnnouncingIOS);
  isAnnouncingIOSRef.current = isAnnouncingIOS;

  const onHistory = useCallback(
    (history: Envelopes[], requiresReset?: boolean) => {
      const historyIDs = new Set();
      const dedupedHistory = history.filter((env) => {
        // echo envelopes from the old bot have the same ID as the InputText for which the echo is generated
        const key = `${env.from}:${env.ID}`;
        if (historyIDs.has(key)) return false;
        historyIDs.add(key);
        return true;
      });

      const inputHistory = dedupedHistory.filter((h) => isInputEnvelope(h)) as InputEnvelope[];
      const chatHistory = dedupedHistory.filter((h) => isChatEnvelope(h)) as ChatEnvelope[];

      const msgs: MessagesObject = {
        chat: [],
        input: [],
      };

      const chatEnvelopes = chatHistory
        .map((env) => ({
          ...env,
          history: true,
          ID: `history_${env.ID}`,
        }))
        .map((env) => {
          return env.kind === Kind.ChatText
            ? getFlatChatTextEnvelopesFromChatTextEnvelope(env)
            : env;
        })
        .flat();

      msgs.chat.unshift(...chatEnvelopes);
      if (inputHistory.length && (!completed || !IS_PRODUCTION)) {
        const lastEcho = [...chatHistory].reverse().find((e) => e.ID.startsWith('echo'));
        const lastInput = inputHistory[inputHistory.length - 1];
        const lastInputHasEcho = lastEcho && lastEcho.t! > lastInput.t!;
        if (!lastInputHasEcho) {
          msgs.input.unshift(lastInput);
        }
      }

      setMessages((curr) => {
        return requiresReset
          ? msgs
          : {
              chat: [...curr.chat, ...msgs.chat],
              input: [...curr.input, ...msgs.input],
            };
      });
    },
    [completed],
  );

  /* eslint-disable */
  const { checkNetworkStatus, status, send, unsubscribe, subscribe } = convo.startsWith(
    TEST_CONVO_PREFIX,
  )
    ? useTestMQTT()
    : useStateMachine(onHistory);
  /* eslint-enable */

  useEffect(() => {
    if (!convo) return;

    const callback = (message: Envelopes) => {
      queueRef.current = produce(queueRef.current, (draft) => {
        if (message.kind === Kind.ChatText) {
          const arr = getFlatChatTextEnvelopesFromChatTextEnvelope(message);
          draft.push(...arr);
        } else {
          draft.push(message);
        }
      });
      incrementRef.current();
    };

    subscribe(convo, callback);
    return () => {
      unsubscribe(convo, callback);
    };
    // eslint-disable-next-line
  }, [convo, completed]);

  const newMessageTimeout = useRef<NodeJS.Timeout>(0 as any);
  const incrementRef = useRef<() => void>(() => {});

  incrementRef.current = function addMessage() {
    if (!newMessageTimeout.current && queueRef.current.length && !isAnnouncingIOSRef.current) {
      const queue = queueRef.current;
      const message = queue[0];
      queueRef.current = queue.slice(1);

      setMessages((_messages) =>
        produce(_messages, (msgs) => {
          if (isChatEnvelope(message)) {
            msgs.chat.push(message);
          } else if (isInputEnvelope(message)) {
            msgs.input.push(message);
          } else if (isSideEffectEnvelope(message)) {
            if (message.kind === Kind.SideEffectNavigate) {
              handleSideEffect({
                kind: 'navigate',
                ...message.props,
                routeName: message.props.routeName as keyof RootStackParamList,
              });
            }
          }
        }),
      );

      newMessageTimeout.current = global.setTimeout(
        () => {
          clearTimeout(newMessageTimeout.current);
          newMessageTimeout.current = 0 as any;
          incrementRef.current();
        },
        getEnvelopeDuration(message, isScreenReaderEnabled) + BUBBLE_APPEAR_OFFSET,
      );
    }
  };

  useEffect(() => {
    if (!isAnnouncingIOS) {
      incrementRef.current();
    }
  }, [isAnnouncingIOS]);

  return {
    checkNetworkStatus,
    status,
    messages,
    send: (message: WritePayload) => {
      Sentry.addBreadcrumb({
        category: 'bot',
        message: 'send-envelope',
        data: { ID: message.ID, kind: message.kind, convo },
      });

      if (equals(lastSentRef.current, message)) {
        Sentry.captureMessage('Potential duplicate envelope sent by user in bot session', {
          level: 'warning',
        });
      }
      lastSentRef.current = message;

      // This is a how we fake bot messages that were generated somewhere on the client
      // but we don't care to send to / persist on the server
      if (message.from === Actor.Bot && message.kind === Kind.InputText) {
        setMessages((msgs) => ({
          ...msgs,
          chat: [
            ...msgs.chat,
            {
              ID: message.ID,
              from: Actor.Bot,
              kind: Kind.ChatText,
              props: { text: [message.props] },
              t: Date.now(),
            },
          ],
        }));
        return;
      }

      send(convo, message);
    },
  };
}
