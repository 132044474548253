import hexToRgba from 'hex-to-rgba';
import { ComponentProps } from 'react';
import { TouchableOpacity } from 'react-native';

import { Icon } from './Icon';
import { Text } from './Text';
import { View } from './View';
import { Color } from '../styles';

export function PracticeItem(props: {
  icon: ComponentProps<typeof Icon>['name'];
  color: string;
  text: string;
  onPress: () => void;
  complete?: boolean;
}) {
  const color = props.complete ? '#008689' : props.color;
  return (
    <TouchableOpacity
      accessibilityLabel={`${props.text}. ${props.complete ? 'completed' : ''}`}
      accessibilityRole="button"
      onPress={props.onPress}
      disabled={props.complete}
    >
      <View row spacing={12}>
        <View
          style={{
            width: 35,
            height: 35,
            backgroundColor: hexToRgba(color ?? '#FF0000', 0.1),
            borderRadius: 10,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon name={props.complete ? 'check' : props.icon} size={18} color={color} />
        </View>
        <Text
          text={props.text}
          style={[
            { flex: 1, marginRight: 12 },
            props.complete ? { opacity: 0.7, textDecorationLine: 'line-through' } : null,
          ]}
          color={props.complete ? '#035759' : undefined}
          size={17}
          weight="semibold"
        />
        {props.complete ? null : (
          <Icon name="caret-right" color={props.complete ? Color.styleGuide.Gray5 : Color.accent} />
        )}
      </View>
    </TouchableOpacity>
  );
}
