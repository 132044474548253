import { StackNavigationOptions } from '@react-navigation/stack';

import { registerChatArtifactPreviewComponent } from '@oui/app-core/src/components/ChatArtifactPreview';
import { HeaderButtons, HeaderItem } from '@oui/app-core/src/components/HeaderButtons';
import {
  RoundedSection,
  RoundedSectionNavigationOptions,
} from '@oui/app-core/src/components/RoundedSection';
import { useMyStoryMyPlanCompositionSections } from '@oui/app-core/src/hooks/useComposition';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { Color } from '@oui/app-core/src/styles';

import { MyPlan } from '../components/MyPlan';
import { StackScreenProps } from '../types/navigation';

function MyPlanHeaderButtons({ color, onPressEdit }: { color?: string; onPressEdit?: () => void }) {
  const { $t } = useI18n();
  return (
    <HeaderButtons>
      <HeaderItem
        iconName="edit"
        color={color}
        title=""
        onPress={onPressEdit}
        accessibilityLabel={$t({ id: 'MyPlan_editButton', defaultMessage: 'Edit MyPlan' })}
      />
    </HeaderButtons>
  );
}

export const MyPlanRoundedOptions = ({
  defaultHeaderHeight,
  navigation,
}: {
  defaultHeaderHeight: number;
  navigation: StackScreenProps<'MyPlan'>['navigation'];
}): Partial<StackNavigationOptions> => ({
  ...RoundedSectionNavigationOptions({
    defaultHeaderHeight,
    tintColor: 'white',
    backgroundColor: Color.styleGuide.LogoLilac,
  }),
  headerRight: ({ tintColor }) => {
    return (
      <MyPlanHeaderButtons
        color={tintColor}
        onPressEdit={() => {
          navigation.navigate('EditMyPlan', {});
        }}
      />
    );
  },
});

export function MyPlanRounded(props: {
  navigation: StackScreenProps<'MyPlan'>['navigation'] | null;
  route: StackScreenProps<'MyPlan'>['route'] | null;
  preview?: boolean;
}) {
  const { loading, data } = useMyStoryMyPlanCompositionSections();
  const { $t } = useI18n();

  return (
    <RoundedSection
      color={Color.styleGuide.LogoLilac}
      title={$t({ id: 'MyPlan_title', defaultMessage: 'MyPlan' })}
      preview={props.preview}
    >
      <MyPlan data={data} loading={loading} />
    </RoundedSection>
  );
}

registerChatArtifactPreviewComponent('MyPlan', () => MyPlanRounded);
