import { useNavigation, useRoute } from '@react-navigation/native';
import { produce } from 'immer';
import { useCallback, useEffect, useRef } from 'react';

import { useAddAction, useCopingCards } from '@oui/app-core/src/hooks/practices';

import { Button } from '@src/components/Button';
import { HeaderButtons } from '@src/components/HeaderButtons';
import { ScrollView } from '@src/components/ScrollView';
import { TextInput } from '@src/components/TextInput';
import { View } from '@src/components/View';
import { useForm } from '@src/hooks/useForm';
import { useI18n } from '@src/lib/i18n';
import { card, useTheme } from '@src/styles';
import { ActionType, StackScreenProps } from '@src/types';

export function EditCopingCards(props: { preview?: boolean }) {
  const { goBack, setOptions } = useNavigation<StackScreenProps<'EditCopingCards'>['navigation']>();
  const route = useRoute<StackScreenProps<'EditCopingCards'>['route']>();
  const { data: persistedData, saveCopingCards } = useCopingCards();
  const { Color } = useTheme();
  const copingCardIndex = route.params?.copingCardIndex
    ? Number.parseInt(route.params.copingCardIndex, 10)
    : undefined;
  const [addAction] = useAddAction();
  const { $t } = useI18n();

  const { data, bind } = useForm<{
    cards: typeof persistedData;
  }>({
    cards:
      typeof copingCardIndex === 'number'
        ? [persistedData[copingCardIndex]]
        : [{ frontText: '', backText: '' }],
  });
  const dataRef = useRef(data);
  dataRef.current = data;

  const save = useCallback(() => {
    const savedCards = persistedData;
    const newData =
      typeof copingCardIndex === 'number'
        ? produce(savedCards, (draft) => {
            draft[copingCardIndex] = dataRef.current.cards[0];
          })
        : [...savedCards, ...dataRef.current.cards];

    const finalNewCards = newData.filter((c) => c && (c.frontText || c.backText));
    const addedCards = finalNewCards.length > savedCards.length;

    return saveCopingCards(finalNewCards).then(() => {
      if (addedCards) {
        addAction({
          actionType: ActionType.COPING_CARD_ADD,
        });
      }
      goBack();
    });
  }, [saveCopingCards, persistedData, copingCardIndex, addAction, goBack]);

  useEffect(() => {
    setOptions({
      headerRight: () => (
        <HeaderButtons>
          <Button
            onPress={save}
            text={$t({ id: 'EditCopingCards_saveButton', defaultMessage: 'Done' })}
            testID="EditCopingCards_saveButton"
          />
        </HeaderButtons>
      ),
    });
  }, [setOptions, save, $t]);

  return (
    <ScrollView
      style={{ backgroundColor: Color.grayBackground }}
      contentContainerStyle={{ padding: 18 }}
      testID="EditCopingCards_scrollView"
    >
      {data.cards.map((_, i) => {
        return (
          <View
            key={i}
            style={[card, { paddingVertical: 25, paddingHorizontal: 20, marginBottom: 30 }]}
            spacing={20}
            accessibilityLabel={$t(
              {
                id: 'EditCopingCards_cardAccessibilityLabel',
                defaultMessage: `Coping card {cardNum}`,
              },
              { cardNum: `${i + 1}` },
            )}
          >
            <TextInput
              {...bind(['cards', i, 'frontText'], {
                label: $t({
                  id: 'EditCopingCards_card_frontLabel',
                  defaultMessage: 'Thought or situation',
                }),
              })}
              placeholder={$t({
                id: 'EditCopingCards_card_frontPlaceholder',
                defaultMessage: 'Write a thought or situtation you might find yourself in',
              })}
              multiline
              inputStyle={{ minHeight: 100 }}
            />
            <TextInput
              {...bind(['cards', i, 'backText'], {
                label: $t({ id: 'EditCopingCards_card_backLabel', defaultMessage: 'Response' }),
              })}
              placeholder={$t({
                id: 'EditCopingCards_card_backPlaceholder',
                defaultMessage: 'What is a helpful way to think about this?',
              })}
              multiline
              inputStyle={{ minHeight: 100 }}
            />
          </View>
        );
      })}
      {typeof copingCardIndex === 'number' ? null : (
        <Button
          testID="EditCopingCards_addCardButton"
          text={$t({ id: 'EditCopingCards_addCardButton', defaultMessage: 'Add card' })}
          icon="plus"
          variant="text"
          alignSelf="center"
          onPress={() => {
            const { value: cards, onChangeValue } = bind('cards', {
              accessibilityLabel: '',
            });
            onChangeValue([...cards, { frontText: '', backText: '' }]);
          }}
        />
      )}
    </ScrollView>
  );
}
