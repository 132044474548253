import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { graphql } from '@oui/lib/src/graphql/tada';

import { Button } from '@src/components/Button';
import { ConfirmationModal } from '@src/components/ConfirmationModal';
import { HeaderButtons, HeaderItem } from '@src/components/HeaderButtons';
import { ScrollView } from '@src/components/ScrollView';
import { Text } from '@src/components/Text';
import { TextInput } from '@src/components/TextInput';
import { useForm } from '@src/hooks/useForm';
import { UserProfileQuery } from '@src/screens/UserProfile';
import { useTheme } from '@src/styles';
import { StackScreenProps } from '@src/types';

const SaveLessonLearnedMutation = graphql(`
  mutation SaveLessonLearned($session: String!, $text: Any!) {
    kvRespond(context: $session, key: "lessonLearned", data: $text) {
      __typename
      context
      key
      value
    }
  }
`);

export function EditLessonsLearned(props: {
  navigation: StackScreenProps<'EditLessonsLearned'>['navigation'];
  route: StackScreenProps<'EditLessonsLearned'>['route'];
}) {
  const { Color } = useTheme();
  const { data, refetch } = useQuery(UserProfileQuery);
  const [saveLesson] = useMutation(SaveLessonLearnedMutation);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const lessonLearnedByIndex = useMemo(() => {
    if (!data?.lessonLearned) return {};
    return Object.entries(data.lessonLearned).reduce<
      Record<string, NonNullable<NonNullable<typeof data>['lessonLearned']>[number]>
    >((carry, [index, lessonLearned]) => {
      carry[index] = lessonLearned;
      return carry;
    }, {});
  }, [data?.lessonLearned]);

  const { data: formData, bind, dirty, clear } = useForm(lessonLearnedByIndex);

  const dataRef = useRef({
    queryData: data?.lessonLearned,
    formData,
  });
  dataRef.current = {
    queryData: data?.lessonLearned,
    formData,
  };

  useEffect(
    () =>
      props.navigation.addListener('beforeRemove', (e) => {
        if (!dirty) {
          return;
        }

        e.preventDefault();

        setShowConfirmationModal(true);
      }),
    [props.navigation, dirty],
  );

  const onSave = useCallback(async () => {
    const promises: Promise<unknown>[] = Object.values(dataRef.current.formData).map((lesson) => {
      const currentValue = dataRef.current.queryData?.find(
        (less) => less.session === lesson.session,
      );
      return currentValue?.text !== lesson.text
        ? saveLesson({
            variables: {
              session: lesson.session,
              text: lesson.text,
            },
          })
        : Promise.resolve();
    });
    await Promise.all(promises);
    await refetch();
    clear();
    props.navigation.goBack();
  }, [refetch, props.navigation, saveLesson, clear]);

  useEffect(() => {
    props.navigation.setOptions({
      headerLeft: ({ tintColor }) => (
        <HeaderButtons>
          <HeaderItem
            accessibilityLabel="Cancel"
            title=""
            onPress={() => {
              props.navigation.goBack();
            }}
            iconName="close"
            color={tintColor}
          />
        </HeaderButtons>
      ),
      headerRight: ({ tintColor }) => (
        <HeaderButtons>
          <Button
            color={tintColor}
            size="small"
            text="Done"
            alignSelf="flex-start"
            onPress={onSave}
            style={{ paddingHorizontal: 14 }}
            testID="EditLessonsLearned_saveButton"
          />
        </HeaderButtons>
      ),
    });
  }, [onSave, props.navigation]);

  return (
    <ScrollView
      testID="EditLessonsLearned"
      style={{ flex: 1, backgroundColor: Color.grayBackground }}
      contentContainerStyle={[
        {
          alignSelf: 'stretch',
          padding: 20,
        },
      ]}
    >
      <Text
        text="Lessons from each session"
        accessibilityRole="header"
        weight="semibold"
        color={Color.styleGuide.Gray4}
        style={{ marginBottom: 12 }}
      />
      {data?.lessonLearned?.map((lesson, i) => {
        const { value, onChangeValue, ...rest } = bind([i], { label: (i + 1).toString() });
        return (
          <TextInput
            labelColor={Color.styleGuide.Gray4}
            labelWeight="normal"
            labelHorizontal
            style={{ marginBottom: 2 }}
            inputStyle={{ flexGrow: 1, marginLeft: 10, flex: 1 }}
            key={lesson.session}
            {...rest}
            value={value.text}
            onChangeValue={(v) => {
              onChangeValue({ ...value, text: v });
            }}
            multiline
          />
        );
      })}
      <ConfirmationModal
        confirmTestID="EditLessonsLearned_confirmationModalSaveButton"
        cancelTestID="EditLessonsLearned_confirmationModalDiscardButton"
        onCancel={() => {
          setShowConfirmationModal(false);
          clear();
          setTimeout(() => props.navigation.goBack(), 10);
        }}
        onConfirm={() => {
          return onSave();
        }}
        visible={showConfirmationModal}
        confirmText="Save"
        cancelText="No, discard"
        title="Save changes?"
        description="You’ve made changes. Would you like to save them before leaving?"
      />
    </ScrollView>
  );
}
