import hexToRgba from 'hex-to-rgba';
import times from 'lodash/times';
// https://github.com/react-native-community/react-native-hooks/blob/7abd32f513db2cc5ecedb504cb50827685ae6d7b/lib/useLayout.js#L1
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Animated, Platform, StyleProp, ViewStyle, type LayoutChangeEvent } from 'react-native';
import {
  PanGestureHandler,
  PanGestureHandlerStateChangeEvent,
  State,
} from 'react-native-gesture-handler';

import { Text } from '../components/Text';
import { View } from '../components/View';
import { useIsSafeLayoutAnimating } from '../hooks/useSafeLayoutAnimation';
import { Shadow, useTheme } from '../styles';

function useLayout() {
  const [layout, setLayout] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  // Ref is useful for situations where we need to use the layout data in a closure
  // that was created and is not being updated with the most recent values. By
  // giving it a ref, it can retrieve the most up to date values when it runs.
  // Consider the following example where width will always be 0 but we want
  // ref.current.width will be updated
  //
  // const { width, ref } = useLayout();
  // const otherHandle = useRef(() => {
  //   console.log(width, ref.current.width);
  // });
  //
  // return <View onLayout={onLayout} />;
  const ref = useRef(layout);

  const onLayout = useCallback((e: LayoutChangeEvent) => {
    setLayout(e.nativeEvent.layout);
    ref.current = e.nativeEvent.layout;
  }, []);

  return {
    onLayout,
    ref,
    ...layout,
  };
}

const RADIUS = 20;
const BAR_HEIGHT = 10;
const GREATER_COLOR = hexToRgba('#FFFFFF', 0.7);

function getTouchX({
  width,
  delta,
  value,
  minimumValue,
}: {
  width: number;
  delta: number;
  value: number;
  minimumValue: number;
}): number {
  const widthDelta = width / delta;
  return (value - minimumValue) * widthDelta;
}

type Props = {
  maximumValue: number;
  minimumValue: number;
  onValueChange?: (val: number) => void;
  step?: number;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  value?: number;
};

export const Slider = forwardRef<{ setValue: (v: number) => void }, Props>(
  (
    {
      maximumValue = 10,
      minimumValue = 0,
      onValueChange,
      step = 1,
      style,
      testID,
      value: initialValue,
    }: Props,
    forwardedRef,
  ) => {
    const isAnimating = useIsSafeLayoutAnimating();
    const [initialized, setInitialized] = useState(false);
    const [value, setValue] = useState(initialValue || 0);
    const { ref, width, onLayout } = useLayout();
    const delta = (maximumValue - minimumValue) / step;
    const touchX = useRef(new Animated.Value(0));
    const { theme } = useTheme();

    useImperativeHandle(forwardedRef, () => ({
      setValue: (newValue: number) => {
        setValue(newValue);
        const refWidth = ref.current.width;
        const widthDelta = refWidth / delta;
        Animated.timing(touchX.current, {
          toValue: ((newValue - minimumValue) * widthDelta) / step,
          duration: 200,
          useNativeDriver: Platform.OS !== 'web',
        }).start();
      },
    }));

    useEffect(() => {
      if (width && !initialized) {
        touchX.current.setValue(getTouchX({ width, delta, minimumValue, value }));
        setInitialized(true);
      }
    }, [width, initialized, delta, value, minimumValue]);

    const listener = (event: PanGestureHandlerStateChangeEvent, isEnd?: boolean) => {
      const refWidth = ref.current.width;
      const x: number = Math.max(0, Math.min(event.nativeEvent.x, refWidth));
      const widthDelta = refWidth / delta;
      const currentValue = Math.round(x / widthDelta) * step + minimumValue;
      if (currentValue !== value) {
        if (onValueChange) {
          onValueChange(currentValue);
        }
        setValue(currentValue);
      }

      if (isEnd) {
        Animated.timing(touchX.current, {
          toValue: ((currentValue - minimumValue) * widthDelta) / step,
          duration: 200,
          useNativeDriver: Platform.OS !== 'web',
        }).start();
      }
    };

    const onPanGestureEvent = useRef(
      Animated.event([{ nativeEvent: { x: touchX.current } }], {
        useNativeDriver: true,
        listener,
      }),
    );

    const xPos = isAnimating
      ? Math.min(Math.max(0, getTouchX({ width, delta, minimumValue, value })), width)
      : touchX.current.interpolate({
          inputRange: [0, width],
          outputRange: [0, width],
          extrapolate: 'clamp',
        });
    const translateX = isAnimating
      ? (xPos as number) - RADIUS
      : Animated.add(xPos, new Animated.Value(-RADIUS));
    return (
      <View
        style={[{ width: '100%' }, style]}
        testID={testID}
        key={isAnimating.toString()}
        importantForAccessibility="no-hide-descendants"
        accessibilityElementsHidden
      >
        <PanGestureHandler
          minDist={0}
          onGestureEvent={onPanGestureEvent.current}
          onHandlerStateChange={(event) => {
            if (event.nativeEvent.state === State.END) {
              listener(event, true);
            }
          }}
        >
          <Animated.View onLayout={onLayout}>
            <View
              style={{
                backgroundColor: theme.slider.color,
                borderRadius: BAR_HEIGHT / 2,
                position: 'absolute',
                height: BAR_HEIGHT,
                top: RADIUS - BAR_HEIGHT / 2,
                width: '100%',
                overflow: 'hidden',
              }}
            >
              <Animated.View
                style={[
                  {
                    backgroundColor: GREATER_COLOR,
                    height: '100%',
                    width: '100%',
                  },
                  {
                    transform: [{ translateX: xPos }],
                  },
                ]}
              />
              {times(delta, (i) => {
                return (
                  <View
                    key={i}
                    style={{
                      backgroundColor: 'white',
                      height: 20,
                      left: (i * width) / delta,
                      position: 'absolute',
                      width: 1,
                    }}
                  />
                );
              })}
            </View>
            <Animated.View
              style={[
                Shadow.medium,
                {
                  alignItems: 'center',
                  backgroundColor: theme.slider.color,
                  borderRadius: RADIUS,
                  height: RADIUS * 2,
                  justifyContent: 'center',
                  width: RADIUS * 2,
                },
                {
                  transform: [{ translateX }],
                },
              ]}
            >
              <Text
                color="white"
                text={value.toString()}
                weight="bold"
                style={{ lineHeight: RADIUS * 2 }}
              />
            </Animated.View>
          </Animated.View>
        </PanGestureHandler>
      </View>
    );
  },
);
