import hexToRgba from 'hex-to-rgba';
import { ComponentProps, ReactNode, useEffect, useState } from 'react';
import { Image, StyleSheet, TouchableOpacity } from 'react-native';

import { GQLDateTime } from '@oui/lib/src/types/scalars';

import { Icon } from './Icon';
import { Pill } from './Pill';
import { SvgUri } from './SvgUri';
import { Label, Text } from './Text';
import { View } from './View';
import { useI18n } from '../lib/i18n';
import { card, useTheme } from '../styles';

function getV2SessionSvg(num: number, completed: boolean) {
  return (
    <SvgUri
      uri={`https://storage.googleapis.com/asset.oui.dev/static/Learn_Session_${
        completed ? 'complete_' : ''
      }${num}.svg`}
    />
  );
}

function getV2StaticSessionSvg(num: number, completed: boolean) {
  return (
    <SvgUri
      uri={`https://storage.googleapis.com/asset.oui.dev/static/LearnStatic_Session_${
        completed ? 'complete_' : ''
      }${num}.svg`}
    />
  );
}

const BORDER_WIDTH = 2;
const COMPLETE_COLOR = '#18655a';
const COMPLETE_COLOR_BACKGROUND = hexToRgba('#c7f0b7', 0.3);

export type SessionState = 'locked' | 'unlocked' | 'active' | 'complete';
export type Session = {
  num: number;
  title: string;
  subtitle?: string;
  details: string[];
  illustrationUrl: string | undefined;
};

type Props = {
  hideHeader?: boolean; // for AVIVA_STATIC
  static?: boolean;
  session: Session;
  state: SessionState;
  unlockedAt?: GQLDateTime | null;
  onPressTop: () => void;
  testID?: string;
};

function SessionCardTitle({
  header,
  state,
  unlockedAt,
  title,
  subtitle,
}: {
  header: string;
  state: SessionState;
  unlockedAt?: GQLDateTime | null;
  title: string;
  subtitle?: string;
}) {
  const { theme } = useTheme();
  const { formatDate } = useI18n();
  const isComplete = state === 'complete';

  let titleColor, subtitleColor;
  let opacity = undefined;

  if (isComplete) {
    titleColor = COMPLETE_COLOR;
    subtitleColor = COMPLETE_COLOR;
  } else if (state === 'locked') {
    titleColor = theme.color.gray100;
    subtitleColor = theme.color.gray200;
    if (!unlockedAt) {
      opacity = 0.7;
    }
  }

  return (
    <View row flex={1}>
      <View row flex={1} style={{ alignItems: 'flex-start', opacity }} spacing={14}>
        {header ? (
          <Text
            // if header is '0' we don't actually want to show 0 as a number since a "0" session
            // isn't actually session 0, it's just the session before 1
            text={header === '0' ? ' ' : header}
            weight="semibold"
            size={17}
            color={titleColor}
          />
        ) : null}
        {title ? (
          <View flex={1}>
            <View row spacing={9}>
              <Text text={title} weight="semibold" size={17} color={titleColor} />
              {state === 'active' || state === 'unlocked' ? (
                <View pointerEvents="none" style={{ marginTop: 3 }}>
                  <Icon name="arrow-right" color={theme.color.primary100} />
                </View>
              ) : null}
            </View>
            {subtitle ? (
              <Text text={subtitle} color={subtitleColor} style={{ maxWidth: '85%' }} />
            ) : null}
            {unlockedAt ? (
              <View row style={{ marginTop: 15, gap: 5 }}>
                <Label text="Unlocks" small />
                <Pill
                  size="medium"
                  backgroundColor="white"
                  color={theme.color.gray200}
                  text={formatDate(unlockedAt, {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                />
              </View>
            ) : null}
          </View>
        ) : null}
        {state === 'locked' ? (
          <View style={{ alignItems: 'flex-end' }}>
            <Icon name="lock" color={theme.color.gray400} size={18} />
          </View>
        ) : null}
      </View>
    </View>
  );
}

export function SessionIncompleteLockCard(props: { num: number; testID: string }) {
  const { scheme } = useTheme();
  return (
    <View
      testID={props.testID}
      accessible
      accessibilityLabel={`Complete session ${props.num} to unlock.`}
      accessibilityRole="button"
      accessibilityState={{
        disabled: true,
      }}
      style={[
        styles.component,
        styles.locked,
        { backgroundColor: scheme === 'dark' ? '#121212' : '#EFF0F3' },
      ]}
    >
      <SessionCardTitle
        title={''}
        subtitle={undefined}
        state="locked"
        header={`Complete session ${props.num}`}
      />
    </View>
  );
}

function NaturalSizeImage(props: ComponentProps<typeof Image>) {
  const [size, setSize] = useState<{ width: number; height: number }>();
  const uri = typeof props.source === 'object' && 'uri' in props.source && props.source.uri;
  const isSvg = typeof uri === 'string' && uri.endsWith('.svg');

  useEffect(() => {
    let cancelled = false;
    if (typeof props.source === 'object' && 'uri' in props.source && props.source.uri) {
      if (props.source.uri.endsWith('.svg')) {
        fetch(props.source.uri)
          .then((r) => r.text())
          .then((xmlString) => {
            const [_full, x, y, width, height] =
              xmlString
                .split('\n')
                .slice(0, 3)
                .find((line) => line.match(/viewBox=".*"/i))
                ?.match(/viewBox="(\d+) (\d+) (\d+) (\d+)"/i)
                ?.map((v) => Number.parseFloat(v)) ?? [];
            if (width && height) {
              setSize({ width: width - x, height: height - y });
            } else {
              // fall back to aspect ratio of 1
              setSize({ width: 90, height: 90 });
            }
          });
      } else {
        Image.getSize(props.source.uri, (width, height) => {
          if (!cancelled) {
            setSize({ width, height });
          }
        });
      }
    }
    return () => {
      cancelled = true;
    };
  }, [props.source]);

  return isSvg ? (
    <SvgUri uri={uri} style={{ width: 90, aspectRatio: size ? size.width / size.height : 1 }} />
  ) : (
    <Image {...props} style={{ width: 90, aspectRatio: size ? size.width / size.height : 1 }} />
  );
}

export function SessionCard(props: Props) {
  const { theme, Color } = useTheme();
  const header = props.hideHeader ? '' : props.session.num.toString();
  const state = props.state;

  if (state === 'locked') {
    const backgroundColor = props.unlockedAt ? theme.color.gray800 : theme.color.gray700;
    const borderColor = props.unlockedAt ? theme.color.gray600 : theme.color.gray700;

    return (
      <View
        accessible
        accessibilityLabel={`Session ${header}. ${props.session.title ?? ''}. ${
          props.session.subtitle ?? ''
        }. locked`}
        accessibilityRole="button"
        accessibilityState={{
          disabled: true,
        }}
        style={[styles.component, styles.locked, { backgroundColor, borderColor }]}
        testID={`${props.testID}_locked`}
      >
        <SessionCardTitle
          title={props.session.title}
          subtitle={props.session.subtitle}
          state="locked"
          header={header}
          unlockedAt={props.unlockedAt}
        />
      </View>
    );
  }

  const isActive = state === 'active';
  const isComplete = state === 'complete';

  const collapsed = !isActive || props.session.details.length === 0;
  const expandedLabel = collapsed ? undefined : `Agenda: ${props.session.details.join('.')}.`;
  const accessibilityLabel = `Session ${props.session.num}. ${props.session.title}. ${
    props.session.subtitle
  }. ${expandedLabel} ${isComplete ? 'completed' : ''}`;

  return (
    <View
      testID={props.testID}
      style={
        !isComplete
          ? [
              {
                borderRadius: 20,
                overflow: 'visible',
              },
            ]
          : null
      }
    >
      <TouchableOpacity
        accessibilityLabel={accessibilityLabel}
        accessibilityRole="button"
        key={state} // Shouldn't be necessary but border doesn't update properly when isActive changes
        testID="SessionCard_top"
        onPress={props.onPressTop}
        style={[
          styles.component,
          state === 'unlocked'
            ? {
                borderColor: 'transparent',
                backgroundColor: theme.color.gray800,
              }
            : undefined,
          isActive
            ? {
                borderColor: theme.color.primary100,
                backgroundColor: 'white',
              }
            : null,
          isComplete
            ? { borderColor: COMPLETE_COLOR_BACKGROUND, backgroundColor: COMPLETE_COLOR_BACKGROUND }
            : null,
        ]}
      >
        <View
          style={{
            position: 'absolute',
            right: -BORDER_WIDTH,
            bottom: -BORDER_WIDTH,
          }}
        >
          {props.session.illustrationUrl ? (
            <NaturalSizeImage
              source={{ uri: props.session.illustrationUrl }}
              resizeMode="contain"
            />
          ) : props.static ? (
            getV2StaticSessionSvg(props.session.num, isComplete)
          ) : (
            getV2SessionSvg(props.session.num, isComplete)
          )}
        </View>
        <View>
          <SessionCardTitle
            title={props.session.title}
            subtitle={props.session.subtitle}
            state={state}
            header={header}
          />
        </View>
        {collapsed ? null : (
          <View style={{ marginTop: 16, marginStart: 22 }}>
            <Text
              text="Agenda"
              color={Color.styleGuide.Gray3}
              weight="semibold"
              style={{ marginBottom: 5 }}
            />
            {props.session.details.map((detail, i) => (
              <View style={{ flexDirection: 'row', marginBottom: 8 }} key={i}>
                <Text
                  text={'•'}
                  style={{ marginEnd: 8, width: 10, textAlign: 'center', color: '#999' }}
                />
                <Text text={`${detail}`} />
              </View>
            ))}
          </View>
        )}
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  component: {
    padding: 18,
    borderColor: 'transparent',
    borderWidth: BORDER_WIDTH,
    borderRadius: 20,
    overflow: 'hidden',
  },
  locked: {
    padding: 22,
  },
});

export function SessionCardBlank(props: { children?: ReactNode; testID?: string }) {
  return (
    <View testID={props.testID} style={[{ overflow: 'visible' }]}>
      <View
        style={[
          card,
          styles.component,
          [
            {
              borderColor: 'transparent',
              minHeight: 80,
            },
          ],
        ]}
      >
        {props.children}
      </View>
    </View>
  );
}
