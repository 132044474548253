import { useQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import { ComponentProps, Fragment, memo, useState } from 'react';
import { StyleSheet } from 'react-native';

import { PillGroup } from '@oui/app-core/src/components/PillGroup';
import { RatingGraph } from '@oui/app-core/src/components/RatingGraph';
import {
  RoundedSection,
  RoundedSectionTopChild,
} from '@oui/app-core/src/components/RoundedSection';
import {
  PracticeFragment,
  shouldSwitchThought,
  shouldTestThought,
  ThoughtDiaryEntryFragment,
  usePracticeRatings,
} from '@oui/app-core/src/hooks/practices';
import { parseGQLDateTime } from '@oui/lib/src/gqlDate';
import { graphql } from '@oui/lib/src/graphql/tada';
import { PracticeType, RatingType } from '@oui/lib/src/types/graphql.generated';

import { Button } from '@src/components/Button';
import Divider from '@src/components/Divider';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { WorksheetListItem } from '@src/components/WorksheetListItem';
import { useArtifactRequest } from '@src/hooks/useArtifactResult';
import { useProgressByContent } from '@src/hooks/useCurrentUser';
import { useI18n } from '@src/lib/i18n';
import { StackScreenProps } from '@src/types';

const LinearGradientMemo = memo(() => (
  <LinearGradient
    colors={['rgba(241, 248, 249, 0)', '#d9eede']}
    style={StyleSheet.absoluteFillObject}
    start={[0, 0]}
    end={[0, 1]}
  />
));

export const ThoughtDiaryEntriesQueryName = 'ThoughtDiaryEntries';
export type ThoughtDiaryEntriesQueryName = typeof ThoughtDiaryEntriesQueryName;
const ThoughtDiaryEntriesQuery = graphql(
  `
    query ThoughtDiaryEntries($before: Date, $after: Date) {
      practices(practiceTypes: [THOUGHT_DIARY_ENTRY], before: $before, after: $after) {
        ...PracticeFragment
        ... on ThoughtDiaryEntryPractice {
          thoughtDiaryEntry {
            ...ThoughtDiaryEntryFragment
          }
        }
      }
    }
  `,
  [PracticeFragment, ThoughtDiaryEntryFragment],
);

function ThoughtDiaryEntries() {
  const { navigate } = useNavigation<StackScreenProps<'ThoughtDiary'>['navigation']>();
  const route = useRoute<StackScreenProps<'ThoughtDiary'>['route']>();
  const { data } = useQuery(ThoughtDiaryEntriesQuery, {
    variables: {},
  });
  const { data: progress } = useProgressByContent();
  const completedSession07 = !!progress.TEST_IT?.completion;
  const completedSession08 = !!progress.SWITCH_IT?.completion;
  const { $t, formatDate } = useI18n();

  const isEmpty = (data?.practices ?? []).length === 0;
  useArtifactRequest(route.name, !isEmpty);

  return (
    <View>
      <View
        row
        style={{
          padding: 20,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          justifyContent: 'center',
        }}
      >
        <Button
          text={$t({ id: 'ThoughDiary_addThoughtButton', defaultMessage: 'Add thought' })}
          icon="plus"
          onPress={() => navigate('EditThoughtDiaryEntry', { step: 'spot', cardStack: true })}
          testID="ThoughtDiary_addThoughtButton"
        />
      </View>
      <View style={{ padding: 20 }}>
        <Text
          text={$t({ id: 'ThoughDiary_entriesHeader', defaultMessage: 'Entries' })}
          weight="semibold"
          accessibilityRole="header"
        />
        <Divider />
        {data?.practices.map((d, i) => {
          if (d.__typename === 'ThoughtDiaryEntryPractice') {
            let buttonProps: ComponentProps<typeof WorksheetListItem>['buttonProps'] = {
              alignSelf: 'flex-start',
            };
            if (
              shouldTestThought({
                completedSession07,
                thoughtDiaryEntry: d.thoughtDiaryEntry,
              })
            ) {
              buttonProps = {
                text: $t({ id: 'ThoughDiary_testItButton', defaultMessage: 'Test it' }),
                variant: 'solid',
                icon: undefined,
                size: 'small',
                alignSelf: 'flex-start',
                onPress: () => {
                  navigate('ThoughtDiaryEntry', { practiceID: d.practiceID });
                  navigate('EditThoughtDiaryEntry', {
                    practiceID: d.practiceID,
                    step: 'test',
                    cardStack: true,
                  });
                },
                testID: `ThoughtDiary_row_${i}_testItButton`,
                accessibilityLabel: $t({
                  id: 'ThoughDiary_testItAccessibilityLabel',
                  defaultMessage: 'Test this thought',
                }),
              };
            } else if (
              shouldSwitchThought({
                completedSession08,
                thoughtDiaryEntry: d.thoughtDiaryEntry,
              })
            ) {
              buttonProps = {
                text: $t({ id: 'ThoughDiary_switchItButton', defaultMessage: 'Switch it' }),
                icon: undefined,
                variant: 'solid',
                size: 'small',
                alignSelf: 'flex-start',
                onPress: () => {
                  navigate('ThoughtDiaryEntry', { practiceID: d.practiceID });
                  navigate('EditThoughtDiaryEntry', {
                    practiceID: d.practiceID,
                    step: 'switch',
                    cardStack: true,
                  });
                },
                testID: `ThoughtDiary_row_${i}_switchItButton`,
                accessibilityLabel: $t({
                  id: 'ThoughDiary_switchItAccessibilityLabel',
                  defaultMessage: 'Switch this thought',
                }),
              };
            }
            return (
              <Fragment key={d.practiceID}>
                <WorksheetListItem
                  text={
                    d.practiceValues.date
                      ? formatDate(parseGQLDateTime(d.practiceValues.date), {
                          weekday: 'short',
                          month: 'short',
                          day: 'numeric',
                        })
                      : ''
                  }
                  description={d.thoughtDiaryEntry.event}
                  onPress={() => navigate('ThoughtDiaryEntry', { practiceID: d.practiceID })}
                  buttonProps={buttonProps}
                  testID={`ThoughtDiary_row_${i}`}
                />
                <Divider />
              </Fragment>
            );
          }
          return null;
        })}
      </View>
    </View>
  );
}

export function ThoughtDiary() {
  const [timeScale, setTimeScale] = useState<'WEEK' | 'MONTH' | 'YEAR'>('WEEK');
  const payload = usePracticeRatings({
    practiceType: PracticeType.THOUGHT_DIARY_ENTRY,
    ratingType: RatingType.RATING_BEFORE,
    timeScale,
  });
  const afterPayload = usePracticeRatings({
    practiceType: PracticeType.THOUGHT_DIARY_ENTRY,
    ratingType: RatingType.RATING_AFTER,
    timeScale,
  });
  const { data: progress } = useProgressByContent();
  const { $t } = useI18n();

  const showNewThoughtRatings = !!progress.SWITCH_IT?.completed;

  return (
    <RoundedSection
      color={'#c1e3c9'}
      secondaryColor={'white'}
      title={$t({ id: 'ThoughDiary_title', defaultMessage: 'Thought Diary' })}
      preview={false}
      testID="ThoughtDiary_scrollView"
    >
      <RoundedSectionTopChild backgroundColor="#F1F8F9">
        <LinearGradientMemo />
        <PillGroup
          accessibilityLabel={$t({
            id: 'ThoughtDiary_timescaleAccessibilityLabel',
            defaultMessage: 'Chart time scale',
          })}
          value={timeScale}
          onChangeValue={setTimeScale}
          items={[
            {
              label: $t({ id: 'ThoughtDiary_ratingWeekLabel', defaultMessage: 'Week' }),
              value: 'WEEK',
            },
            {
              label: $t({ id: 'ThoughtDiary_ratingMonthLabel', defaultMessage: 'Month' }),
              value: 'MONTH',
            },
            {
              label: $t({ id: 'ThoughtDiary_ratingYearLabel', defaultMessage: 'Year' }),
              value: 'YEAR',
            },
          ]}
        />
        <View style={{ height: 300, marginTop: 20 }}>
          {showNewThoughtRatings ? (
            <RatingGraph
              accessibilityLabel={$t({
                id: 'ThoughtDiary_ratingGraph_AccessibilityLabel',
                defaultMessage: 'A graph of thought helpfulness comparing old and new thoughts.',
              })}
              showDots={timeScale === 'WEEK'}
              xLabels={payload.xLabels}
              ratings={[payload.data, afterPayload.data]}
              legend={[
                {
                  color: '#989d99',
                  text: $t({
                    id: 'ThoughtDiary_ratingGraph_legendBefore',
                    defaultMessage: 'Old thought',
                  }),
                },
                {
                  color: '#008689',
                  text: $t({
                    id: 'ThoughtDiary_ratingGraph_legendAfter',
                    defaultMessage: 'New thought',
                  }),
                },
              ]}
              yAxisLabel={$t({
                id: 'ThoughtDiary_ratingGraph_yAxisLabel',
                defaultMessage: 'Helpfulness rating',
              })}
              xAxisLabel={payload.xAxisLabel}
            />
          ) : (
            <RatingGraph
              accessibilityLabel={$t({
                id: 'ThoughtDiary_beforeRatingGraph_graphAccessibilityLabel',
                defaultMessage: 'A graph of thought helpfulness for spotted thoughts.',
              })}
              showDots={timeScale === 'WEEK'}
              xLabels={payload.xLabels}
              ratings={[payload.data]}
              legend={[
                {
                  color: '#008689',
                  text: $t({
                    id: 'ThoughtDiary_beforeRatingGraph_Legend',
                    defaultMessage: 'Thought helpfulness',
                  }),
                },
              ]}
              yAxisLabel={$t({
                id: 'ThoughtDiary_beforeRatingGraph_yAxisLabel',
                defaultMessage: 'Helpfulness rating',
              })}
              xAxisLabel={payload.xAxisLabel}
            />
          )}
        </View>
      </RoundedSectionTopChild>
      <View
        style={{
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          marginTop: -14,
          marginHorizontal: -20,
          backgroundColor: 'white',
        }}
      >
        <ThoughtDiaryEntries />
        <View style={{ width: '100%', height: 50 }} />
      </View>
    </RoundedSection>
  );
}
