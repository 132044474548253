import hexToRgba from 'hex-to-rgba';
import { Image, Linking } from 'react-native';

import { useDailyState } from '@oui/app-core/src/hooks/useDailyState';

import { Button } from '@src/components/Button';
import { Icon } from '@src/components/Icon';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useI18n } from '@src/lib/i18n';
import { Color } from '@src/styles';

export function SuicideLifelineCard() {
  const { $t } = useI18n();
  const [isSuicideLifelineCardHidden, setIsSuicideLifelineCardHidden] = useDailyState(
    'SuicideLifelineCardHidden',
    false,
  );
  return isSuicideLifelineCardHidden ? null : (
    <View
      style={[
        {
          backgroundColor: hexToRgba(Color.styleGuide.BrandPastelPink, 0.1),
          borderColor: Color.styleGuide.Gray6,
          borderRadius: 20,
          borderWidth: 1,
          padding: 24,
          paddingBottom: 16, // account for internal button padding to make content appear vertically centered
        },
      ]}
    >
      <View row spacing={10}>
        <Image
          accessible
          accessibilityLabel={$t({
            id: 'SuicideLifelineCard_lifelineLogoAccessibilityLabel',
            defaultMessage: 'National Suicide Prevention Lifeline logo',
          })}
          accessibilityRole="image"
          source={require('../assets/national-suicide-prevention-lifeline.jpg')}
          style={{
            height: 70,
            width: 90,
            resizeMode: 'contain',
            alignSelf: 'flex-start',
          }}
        />
        <View flex={1}>
          <Text
            text={$t({
              id: 'SuicideLifelineCard_description',
              defaultMessage:
                'Keep in mind, if you need help right away, call for free confidential help.',
            })}
          />
        </View>
      </View>
      <Button
        accessibilityLabel={$t({
          id: 'SuicideLifelineCard_callButtonAccessibilityLabel',
          defaultMessage: 'Call national suicide lifeline',
        })}
        style={{ marginLeft: 100 }}
        variant="text"
        icon="phone"
        text={$t(
          {
            id: 'SuicideLifelineCard_callButton',
            defaultMessage: 'Call {phoneNumber}',
          },
          { phoneNumber: '988' },
        )}
        testID="SuicideLifelineCard_callButton"
        onPress={() => Linking.openURL('tel:988')}
      />
      <Icon
        accessibilityLabel={$t({
          id: 'SuicideLifelineCard_hideButton',
          defaultMessage: 'Hide card',
        })}
        size={16}
        name="close"
        style={{ position: 'absolute', top: 15, right: 15 }}
        color={Color.styleGuide.Gray5}
        onPress={() => setIsSuicideLifelineCardHidden(true)}
      />
    </View>
  );
}
