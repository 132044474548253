import { ComponentProps, PropsWithChildren, ReactNode, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import ViewShot from 'react-native-view-shot';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import Divider from '@oui/app-core/src/components/Divider';
import { Icon } from '@oui/app-core/src/components/Icon';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { MyStoryMyPlanCompositionDataHash } from '@oui/app-core/src/hooks/useComposition';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { card, Color } from '@oui/app-core/src/styles';

import { EnvironmentSafetySection } from '../components/EnvironmentSafetySection';
import { MyPlanExport } from '../components/MyPlanExport';
import { PatientMyPlan } from '../components/PatientMyPlan';

function SafetyStepsHeader({ cardUI }: { cardUI?: boolean }) {
  const { $t } = useI18n();
  return (
    <View
      style={[
        {
          paddingHorizontal: 20,
          paddingBottom: 10,
          marginBottom: 10,
          marginHorizontal: -10,
          borderBottomWidth: 1,
          borderColor: Color.styleGuide.Gray6,
        },
        cardUI
          ? {
              marginTop: -10,
              marginHorizontal: -20,
            }
          : null,
      ]}
    >
      <Text
        text={$t({ id: 'MyPlan_stepsHeading', defaultMessage: 'Safety steps' })}
        color={Color.styleGuide.Gray3}
        weight="semibold"
        accessibilityRole="header"
      />
    </View>
  );
}

function MyPlanContainer({
  hasData,
  cardUI,
  children,
}: PropsWithChildren<{ hasData: boolean; cardUI?: boolean }>) {
  return (
    <View
      testID="MyPlan"
      style={
        cardUI
          ? [
              card,
              {
                alignSelf: 'stretch',
                backgroundColor: !hasData ? Color.grayBackground : Color.surfaceColor,
                borderRadius: 20,
                marginHorizontal: 20,
                marginTop: 20,
                padding: 20,
              },
            ]
          : [
              {
                alignSelf: 'stretch',
                backgroundColor: 'white',
              },
            ]
      }
    >
      <SafetyStepsHeader cardUI={cardUI} />
      {children}
    </View>
  );
}

function MyPlanLocked() {
  const { $t } = useI18n();
  return (
    <View spacing={35} testID="MyPlan_locked">
      <Text text="">
        {$t({
          id: 'MyPlan_lockedDescription1',
          defaultMessage:
            'This is where you can come look at and edit your MyPlan or safety plan after you complete session 1.',
        })}
        {'\n\n'}
        <FormattedMessage
          id="MyPlan_lockedDescription2"
          defaultMessage="This icon {icon} will always take you to your MyPlan."
          values={{
            icon: (
              <Icon
                name="my-plan"
                color={Color.styleGuide.Gray5}
                accessibilityLabel={$t({
                  id: 'MyPlan_aidIcon',
                  defaultMessage: 'Aid icon',
                })}
              />
            ),
          }}
        />
      </Text>
      <View row style={{ justifyContent: 'space-between' }}>
        <Text
          text={$t({ id: 'MyPlan_lockedUntil', defaultMessage: 'Finish session 1' })}
          weight="semibold"
        />
        <Icon name="lock" color={Color.styleGuide.Gray5} />
      </View>
    </View>
  );
}

export function MyPlan({
  data,
  loading,
  cardUI,
  lockedNode,
  highlightedSections,
}: {
  loading: boolean;
  data: MyStoryMyPlanCompositionDataHash | undefined;
  cardUI?: boolean;
  lockedNode?: ReactNode;
} & Pick<ComponentProps<typeof PatientMyPlan>, 'highlightedSections'>) {
  const viewShotRef = useRef<ViewShot>(null);

  return loading && !data ? (
    <ActivityIndicator />
  ) : (
    <>
      <ViewShot ref={viewShotRef}>
        <MyPlanContainer cardUI={cardUI} hasData={!!data}>
          {data ? (
            <PatientMyPlan
              data={data}
              isEditing={false}
              onEdit={() => {}}
              highlightedSections={highlightedSections}
            />
          ) : (
            lockedNode ?? <MyPlanLocked />
          )}
        </MyPlanContainer>
        {cardUI ? (
          <View style={{ margin: 20 }}>
            <EnvironmentSafetySection
              data={data}
              cardUI
              highlightedSections={highlightedSections}
            />
          </View>
        ) : (
          <View style={{ marginTop: 100 }}>
            <Divider />
            <EnvironmentSafetySection data={data} highlightedSections={highlightedSections} />
          </View>
        )}
      </ViewShot>
      {data ? (
        cardUI ? (
          <MyPlanExport
            getViewShot={() => {
              return viewShotRef.current;
            }}
            style={{
              borderColor: Color.styleGuide.Gray6,
              marginTop: 20,
              borderTopWidth: 1,
              marginHorizontal: -20,
              padding: 20,
              alignItems: 'center',
            }}
          />
        ) : (
          <View
            style={{
              marginTop: 100,
            }}
          >
            <MyPlanExport
              getViewShot={() => {
                return viewShotRef.current;
              }}
              style={{
                marginHorizontal: -20,
                marginBottom: -20,
                padding: 20,
                alignItems: 'center',
              }}
            />
          </View>
        )
      ) : null}
    </>
  );
}
