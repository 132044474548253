import { useNavigation, useRoute } from '@react-navigation/native';
import { Component, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Animated, Easing, Keyboard, KeyboardEvent, Platform, StatusBar } from 'react-native';
import RNAnimated, {
  Easing as RNEasing,
  SlideInDown,
  SlideOutDown,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { ActionEnvelope, Actor, Kind, OnInputCallback } from '@oui/lib/src/types';
import { ContentType } from '@oui/lib/src/types/graphql.generated';

import NotConnected from '../assets/notconnected.svg';
import { Button } from '../components/Button';
import { ChatV2 } from '../components/Chat';
import { ChatInputV2 } from '../components/ChatInput';
import { useConversationContext } from '../components/ConversationContext';
import { SessionTab, SessionTabs } from '../components/Session/Tabs/Tabs';
import { VideosActivities } from '../components/Session/Tabs/VideosActivities';
import { Text } from '../components/Text';
import { View } from '../components/View';
import { manifest } from '../constants';
import { ChatSideEffectContext, SideEffect } from '../hooks/useChatSideEffect';
import { ClientStatus, useConversation } from '../hooks/useConversation';
import { useProgressByContent, useProgressFetcher } from '../hooks/useCurrentUser';
import { useI18n } from '../lib/i18n';
import { logEvent } from '../lib/log';
import Sentry from '../sentry';
import { Shadow, useTheme } from '../styles';
import { StackScreenProps } from '../types/navigation';

type Props = Partial<StackScreenProps<'Conversation'>> & { _cmsEmbed?: true };
type ActionEnvelopeExtraFields = { ID: string; from: Actor; t: number | string };

function ConversationV2Inner(props: Props) {
  const navigation = useNavigation<StackScreenProps<'Conversation'>['navigation']>();
  const route = useRoute<StackScreenProps<'Conversation'>['route']>();
  const { scheme, Color } = useTheme();
  const safeAreaInsets = useSafeAreaInsets();

  const keyboardTranslateY = useSharedValue(0);
  const translateY = useRef(new Animated.Value(0));
  const [inputHeight, setInputHeight] = useState(0);
  const fetchProgress = useProgressFetcher();

  const convoID = route.params?.ID;
  const completed = route.params?.completed === 'true';

  useEffect(() => {
    logEvent('view_session', { session: convoID });
  }, [convoID]);

  const handleSideEffect = (effect: SideEffect) => {
    if (effect.kind === 'navigate') {
      if (effect.routeName === 'home') {
        if (!props._cmsEmbed) {
          fetchProgress();
          navigation.goBack();
        }
      } else {
        // @ts-expect-error we don't have precise enough types for routeName/params so we just have to trust
        // the SideEffect
        navigation.navigate(effect.routeName, effect.params);
      }
    }
  };

  const { checkNetworkStatus, status, messages, send } = useConversation(
    convoID,
    completed,
    handleSideEffect,
  );
  const [ignoreNetworkError, setIgnoreNetworkError] = useState(false);

  useLayoutEffect(() => {
    setIgnoreNetworkError(false);
  }, [status]);

  const handleInput: OnInputCallback<{ ID: string; from?: Actor }> = (payload) => {
    send({
      ...payload,
      t: Date.now(),
    } as ActionEnvelope & ActionEnvelopeExtraFields);
  };

  const hasEnvelopes = !!(messages.input.length || messages.chat.length);

  function onInputChangeHeight(height: number) {
    setInputHeight(height);
    Animated.timing(translateY.current, {
      easing: Easing.inOut(Easing.ease),
      useNativeDriver: true,
      toValue: -height,
      duration: 200,
    }).start();
  }

  useEffect(() => {
    // other platforms handle keyboard at createSessionTimeoutNavigator
    if (Platform.OS !== 'android') return;
    const _onKeyboardChange = (event: KeyboardEvent) => {
      if (event == null) return;
      keyboardTranslateY.value = withTiming(-event.endCoordinates.height);
    };

    const remove1 = Keyboard.addListener('keyboardDidHide', _onKeyboardChange);
    const remove2 = Keyboard.addListener('keyboardDidShow', _onKeyboardChange);
    return () => {
      remove1.remove();
      remove2.remove();
    };
  }, [keyboardTranslateY]);

  const lastChatEnvelope = messages.chat[messages.chat.length - 1];
  return (
    <RNAnimated.View
      style={{
        flex: 1,
        backgroundColor: scheme === 'dark' ? Color.backgroundColor : Color.styleGuide.Gray8,
        paddingBottom: safeAreaInsets.bottom,
        transform: [{ translateY: keyboardTranslateY }],
      }}
      testID="Conversation"
    >
      <ChatSideEffectContext.Provider value={handleSideEffect}>
        <View flex={1}>
          <Animated.View
            style={[
              {
                flex: 1,
                zIndex: 1,
                transform: [{ translateY: translateY.current }],
              },
              Platform.OS === 'ios'
                ? // on iOS translateY causes the ChatV2 component to be rendered at a smaller Y value
                  // (above) than the screen header.
                  // This results in VoiceOver ordering elements in the following manner:
                  // * Last chat message
                  // * Screen header elements
                  // * ChatInput
                  // rather than the order we actually want. By applying a marginTop, we "fix" the order
                  {
                    marginTop: inputHeight,
                  }
                : null,
            ]}
          >
            <ChatV2 messages={messages.chat} hasEnvelopes={hasEnvelopes} />
          </Animated.View>
          {hasEnvelopes ? (
            <Animated.View
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 3,
                transform: [
                  {
                    translateY: translateY.current.interpolate({
                      inputRange: [-inputHeight, 0],
                      outputRange: [0, inputHeight],
                      extrapolate: 'clamp',
                    }),
                  },
                ],
              }}
            >
              <ChatInputV2
                onInputChangeHeight={onInputChangeHeight}
                onInput={handleInput}
                curr={messages.input[messages.input.length - 1]}
                lastTextWasUser={messages.chat[messages.chat.length - 1]?.from === Actor.User}
                lastTextContent={
                  lastChatEnvelope?.kind === Kind.ChatText
                    ? lastChatEnvelope.props.text[0]?.replace(/\*/g, '')
                    : undefined
                }
              />
            </Animated.View>
          ) : null}
        </View>
      </ChatSideEffectContext.Provider>
      {ignoreNetworkError ||
      [ClientStatus.CONNECTED || ClientStatus.UNKNOWN].includes(status) ? null : (
        <RNAnimated.View
          pointerEvents="box-none"
          style={{
            bottom: 0,
            height: 400,
            left: 0,
            position: 'absolute',
            right: 0,
            zIndex: 3,
            elevation: 10,
          }}
          entering={SlideInDown.duration(400).easing(RNEasing.in(RNEasing.ease))}
          exiting={SlideOutDown.duration(400).easing(RNEasing.in(RNEasing.ease))}
        >
          <View
            testID="Conversation_networkIssue"
            style={[
              Shadow.high,
              {
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                borderTopStartRadius: 20,
                borderTopEndRadius: 20,
                backgroundColor: Color.backgroundColor,
                alignItems: 'center',
                padding: 25,
                paddingHorizontal: 45,
              },
            ]}
            spacing={8}
          >
            <NotConnected accessibilityLabel={undefined} />
            <Text text="You're offline" weight="bold" size={21} />
            <Text
              text={`${manifest.name} is having trouble connecting. Check your wifi or data connection.`}
              textAlign="center"
            />
            <View row spacing={24}>
              <Button
                variant="text"
                text="Dismiss"
                onPress={() => {
                  setIgnoreNetworkError(true);
                }}
                style={{ marginVertical: 25 }}
                testID="Converation_networkIssueDismissButton"
              />
              <Button
                text="Retry"
                onPress={() => {
                  Sentry.captureMessage('retry network connection');
                  return checkNetworkStatus();
                }}
                style={{ marginVertical: 25 }}
              />
            </View>
          </View>
        </RNAnimated.View>
      )}
    </RNAnimated.View>
  );
}

const ConversationDisplayMode = (props: Props) => {
  const { data: progress } = useProgressByContent();
  const { $t } = useI18n();
  const navigation = useNavigation<StackScreenProps<'Conversation'>['navigation']>();
  const route = useRoute<StackScreenProps<'Conversation'>['route']>();
  const { showTabsAfterCompletion, showTabsBeforeCompletion } = useConversationContext();
  const showTabs =
    showTabsBeforeCompletion ||
    (showTabsAfterCompletion && progress[route.params.ID as ContentType]?.completed);

  useEffect(() => {
    if (showTabs) {
      navigation.setOptions({ headerStyle: { shadowColor: 'transparent' } });
    }
  }, [showTabs, navigation]);

  if (showTabs) {
    const chatTab: SessionTab = {
      value: 'chat',
      text: $t({ id: 'Conversation_chatTabText', defaultMessage: 'Chat' }),
      icon: 'chat-double',
      Component: <ConversationV2Inner {...props} />,
    };

    const videoTab: SessionTab = {
      value: 'video',
      text: $t({ id: 'Conversation_videoTabText', defaultMessage: 'Videos & activities' }),
      icon: 'video',
      Component: (
        <VideosActivities
          contentType={route.params.ID as ContentType}
          onComplete={navigation.goBack}
          testID="Conversation_videosAndActivitiesScrollView"
        />
      ),
    };

    return <SessionTabs defaultTab="chat" sessionTabs={[chatTab, videoTab]} />;
  }

  return <ConversationV2Inner {...props} />;
};

export class Conversation extends Component<Props, { hasError: boolean }> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    Sentry.withScope((scope) => {
      scope.setExtra('ConversationID', this.props.route?.params?.ID);
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <View style={{ padding: 20 }}>
          <Text text="Something went wrong" />
        </View>
      );
    }

    return (
      <>
        <StatusBar barStyle={'dark-content'} translucent backgroundColor="#00000000" />
        <ConversationDisplayMode {...this.props} />
      </>
    );
  }
}
