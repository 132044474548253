import { ApolloClient, ApolloProvider } from '@apollo/client';
import {
  NavigationContext,
  NavigationProp,
  NavigationRouteContext,
  Route,
} from '@react-navigation/native';
import { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';

import { setTransitionBot } from '@oui/app-core/src/hooks/useConversation';
import { createApolloClient } from '@oui/app-core/src/lib/apolloClient';
import { LoginMutation } from '@oui/app-core/src/lib/auth';
import { useIframeProps } from '@oui/app-core/src/lib/iframeUtils';
import { transitionBot } from '@oui/app-core/src/lib/transitionBotLocal';
import { Conversation } from '@oui/app-core/src/screens/Conversation';
import { CreateTestUserMutation } from '@oui/app-core/src/screens/CreateTestUser';
import { registerConvo } from '@oui/lib/src/botContent';

import { Button } from '@src/components/Button';
import { Heading, Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { API_URL } from '@src/constants';

export function CMSConversationPreview() {
  const props = useIframeProps('CMSConversationPreviewEvent');

  useEffect(() => {
    if (props?.content) {
      registerConvo('test-convo', props?.content);
    }
  }, [props?.content]);

  return props?.content ? <CMSConversationPreviewInner /> : null;
}

function CMSConversationPreviewInner({}: {}) {
  const patientAuthToken = useRef('');
  const [isInitialized, setIsInitialized] = useState<ApolloClient<unknown>>();
  const [isInsideArtifact, setIsInsideArtifact] = useState('');

  useEffect(() => {
    async function setup() {
      const apollo = createApolloClient(API_URL, {
        getAuthHeader: async () => {
          return patientAuthToken.current ? `Bearer ${patientAuthToken.current}` : '';
        },
      });

      if (!patientAuthToken.current) {
        const email = `detox+convotester-${Date.now()}@oui.dev`;
        const password = Math.random().toString();

        await apollo.mutate({
          mutation: CreateTestUserMutation,
          variables: { email, password, testPatient: {} },
        });
        const result = await apollo.mutate({
          mutation: LoginMutation,
          variables: { email, password },
          context: {
            headers: {
              // Spoof login as coming from patient app so login is successful
              'X-Oui-Client': 'oui-aviva',
            },
          },
        });
        if (result.data!.loginWithEmail.__typename !== 'UserSession')
          throw new Error(result.data?.loginWithEmail.message);
        patientAuthToken.current = result.data!.loginWithEmail.token.value;
        setIsInitialized(apollo);
      }

      setTransitionBot(async (_, ...args) => {
        return transitionBot(apollo, ...args, { forceLegacyStaticContent: true });
      });
    }
    setup();
  }, []);

  const navigate: NavigationProp<{}>['navigate'] = useCallback((screen) => {
    if (typeof screen === 'object' && 'name' in screen) {
      setIsInsideArtifact(screen.name);
    } else if (typeof screen === 'string') {
      setIsInsideArtifact(screen);
    }
  }, []);

  const goBack = useCallback(() => {
    setIsInsideArtifact('');
  }, []);

  const [dynamicParams, setDynamicParams] = useState({});
  const route: Route<'Conversation', { ID: string }> = {
    key: '',
    name: 'Conversation',
    params: { ID: 'test-convo', ...dynamicParams },
  };

  if (!isInitialized) return null;
  return (
    <ApolloProvider client={isInitialized}>
      <NavigationContext.Provider
        value={{
          getId: () => '',
          navigate,
          goBack,
          isFocused: () => false,
          addListener: () => {
            return () => {};
          },
          // eslint-disable-next-line
          setParams: (params: any) => {
            if ('_artifactResult' in params) {
              setDynamicParams(params);
            }
          },
          canGoBack: () => false,
          dispatch: () => {},
          getParent: () => null!,
          getState: () => null!,
          removeListener: () => {},
          reset: () => {},
          setOptions: () => {},
        }}
      >
        <NavigationRouteContext.Provider value={route}>
          <Conversation
            _cmsEmbed
            navigation={
              {
                goBack,
                navigate,
              } as any // eslint-disable-line
            }
            route={route}
          />
          {isInsideArtifact ? (
            <View
              style={[StyleSheet.absoluteFillObject, { backgroundColor: 'white' }]}
              spacing={20}
            >
              <Heading level={2} text={isInsideArtifact} />
              <Text
                text={`On a real user's app, the user would be brought to "${isInsideArtifact}".\n\nUpon navigation back to the session, the user widget will send it's (in)complete state back to the chatbot. Choose a path by pressing the corresponding button`}
              />
              <View row style={{ justifyContent: 'space-between' }}>
                <Button
                  text="Incomplete"
                  onPress={() => {
                    setDynamicParams({
                      _artifactResult: { complete: false },
                    });
                    setIsInsideArtifact('');
                  }}
                />
                <Button
                  text="Complete"
                  onPress={() => {
                    setDynamicParams({
                      _artifactResult: { complete: true },
                    });
                    setIsInsideArtifact('');
                  }}
                />
              </View>
            </View>
          ) : null}
        </NavigationRouteContext.Provider>
      </NavigationContext.Provider>
    </ApolloProvider>
  );
}
